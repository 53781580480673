import React from 'react';

import LoginView from './LoginView';

/**
 * 로그인
 * @returns
 */
function Login() {
  return <LoginView />;
}

export default Login;
