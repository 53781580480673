import ReactDom from 'react-dom';

/**
 * 팝업 컴포넌트
 * @param {*} children 팝업 내부에 들어갈 자식 컴포넌트
 * @returns 팝업
 */
const Popup = ({ children }) => {
  const el = document.getElementById('popup');
  return ReactDom.createPortal(children, el);
};

export default Popup;
