/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/support/sub-visual.jpg';

function ContactUsView({ formik }) {
  return (
    <StyledSubContainer
      isVisible
      title="Support"
      subTxt="바능의 다양한 소식을 보실 수 있습니다."
      subVisual={SubVisualImg}
      className="sub-support"
    >
      <article className="c-contactUs">
        <div className="inner-container__sub">
          <h3>문의하기</h3>
          <p className="info-txt">
            바능으로 문의사항을 보내주시면, 담당자 검토후 최대한 빠른 시일내에
            답변 드리도록 하겠습니다.
          </p>
        </div>
        <div className="c-contactUs__form">
          <div className="inner-container__sub">
            <form
              id="inquiryForm"
              name="inquiryForm"
              className="inquiry-form"
              // onSubmit={onSubmit}
              onSubmit={formik.handleSubmit}
            >
              <fieldset>
                <legend>문의하기</legend>
                <div className="inquiry-form__item">
                  {/* <pre>
                    errors:{JSON.stringify(formik.errors, null, 2)}
                    <br />
                    touched:{JSON.stringify(formik.touched, null, 2)}
                    <br />
                    initialErrors :{' '}
                    {JSON.stringify(formik.initialErrors, null, 2)}
                    <br />
                    initialTouched :{' '}
                    {JSON.stringify(formik.initialTouched, null, 2)}
                    <br />
                    initialStatus :{' '}
                    {JSON.stringify(formik.initialStatus, null, 2)}
                    <br />
                    isValid : {JSON.stringify(formik.isValid, null, 2)}
                    <br />
                    dirty : {JSON.stringify(formik.dirty, null, 2)}
                    <br />
                    isSubmitting :{' '}
                    {JSON.stringify(formik.isSubmitting, null, 2)}
                    <br />
                    isValidating :{' '}
                    {JSON.stringify(formik.isValidating, null, 2)}
                    <br />
                  </pre> */}

                  <label htmlFor="company" className="required">
                    회사명
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    placeholder="회사명을 입력해 주세요.(필수)"
                    maxLength={50}
                    // value={company}
                    // onChange={onChangeSubject}
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    style={{
                      borderColor: `${
                        formik.errors.company && formik.touched.company
                          ? 'red'
                          : ''
                      }`,
                    }}
                  />
                  {formik.errors.company && formik.touched.company && (
                    <p style={{ color: 'red' }}>{formik.errors.company}</p>
                  )}
                </div>
                <div className="inquiry-form__item">
                  <label htmlFor="questions">문의사항</label>
                  <textarea
                    id="questions"
                    name="questions"
                    rows="10"
                    placeholder="주식회사 바능은 고객 문의사항 접수 및 답변을 위해 아래와 같이 개인정보를 수집, 이용 합니다.&#13;&#10;목적 : 고객문의사항 접수 및 답변&#13;&#10;항목 : 이름, 직급, 부서, 회사명, 연락처(휴대전화 번호 또는 유선 전화번호, 이메일 주소)&#13;&#10;보유기간 : 수집일로 부터 1년&#13;&#10;서비스 이용에 필요한 최소한의 수집 및 이용에 동의하지 않을 수 있으나, 동의를 거부할 경우 서비스 이용에 제한이 있을 수 있습니다."
                    maxLength={2500}
                    // value={content}
                    // onChange={onChangeContent}
                    value={formik.values.questions}
                    onChange={formik.handleChange}
                    style={{
                      borderColor: `${
                        formik.errors.questions && formik.touched.questions
                          ? 'red'
                          : ''
                      }`,
                    }}
                  />
                  {formik.errors.questions && formik.touched.questions && (
                    <p style={{ color: 'red' }}>{formik.errors.questions}</p>
                  )}
                </div>
              </fieldset>
              <div className="c-contactUs__btn">
                <Button
                  type="submit"
                  size="md"
                  variant="default"
                  // disabled={formik.isSubmitting || !formik.isValid}
                >
                  문의하기
                </Button>
              </div>
            </form>
          </div>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  .c-contactUs {
    &__form {
      background: var(--color-gray-F6F8FC);
      .inquiry-form {
        &__item {
          & + [class*='__item'] {
            margin-top: 4.8rem;
          }
          label {
            display: inline-block;
            color: var(--color-black);
            font-size: 1.8rem;
            font-weight: 600;
            letter-spacing: -0.065em;
            margin-bottom: 1.3rem;
            &.required {
              &::after {
                content: '*';
                margin-left: 5px;
                color: var(--color-red-FF3200);
              }
            }
          }
          input,
          textarea {
            width: 100%;
            padding: 2rem;
            font-size: 1.6rem;
          }
          textarea {
            /* height: 25rem; */
            line-height: 1.5;
          }
        }
      }
    }
    &__btn {
      margin-top: 8rem;
      text-align: center;
      a {
        padding-right: 10rem;
        padding-left: 10rem;
        font-size: 1.7rem;
        font-weight: 500;
      }
    }
  }
`;

export default ContactUsView;

ContactUsView.propTypes = {
  formik: PropTypes.shape({}),
};

ContactUsView.defaultProps = {
  formik: {},
};
