import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import SubContainer from '../../atoms/SubContainer';
import KoreantistDariImg from '../../../assets/img/ourWork/koreantist-dari-img.jpg';

function CommunityView() {
  return (
    <StyledSubContainer title="커뮤니티" className="sub-community">
      <div className="inner-container__sub">
        <article className="c-community">
          <div className="c-community__aside">
            <div className="community__user">
              <div className="item-cont">
                <div className="item-thumb">
                  <img src={KoreantistDariImg} alt="" />
                </div>
                <div className="item-info">
                  <p>
                    <strong>바능</strong>님
                  </p>
                  <Button type="button" size="sm" variant="normal">
                    로그아웃
                  </Button>
                </div>
              </div>
            </div>
            <div className="community__add">
              <Link to="#">
                <div className="item-thumb">
                  <i className="ri-cup-line" />
                </div>
                <div className="item-info">
                  <strong className="title">커뮤니티 만들기</strong>
                  <div className="info">지금 커뮤니티를 생성해 보세요.</div>
                </div>
              </Link>
            </div>
            <div className="community__txt">
              <i className="ri-chat-check-line" />
              <p>
                허위 글로 사기 유도할 시 법적 책임을 요구합니다. 그에 따른
                책임은 지지 않습니다.
              </p>
            </div>
          </div>
          <div className="c-community__content">
            <ul className="community__tab">
              <li className="active">
                <Link to="#">전체</Link>
              </li>
              <li>
                <Link to="#">내카페</Link>
              </li>
              <li>
                <Link to="#">운영카페</Link>
              </li>
            </ul>
            <ul className="community__list">
              <li className="list-item">
                <span className="badge badge--waiting">승인대기중</span>
                <div className="item-cont">
                  <div className="item-thumb">
                    <img src={KoreantistDariImg} alt="" />
                  </div>
                  <div className="item-info">
                    <Link to="#">
                      <strong className="title">네일동 : 일본여행카페</strong>
                    </Link>
                    <div className="info">
                      <span>운영자</span>
                      <span>23-02-13</span>
                    </div>
                  </div>
                </div>
                <ul className="item-list">
                  <li>
                    <Link to="#">
                      <p className="title">
                        <strong>
                          오사카 여행 교통권 어떻게 하는게 좋을까요~?
                        </strong>
                        <span>
                          <span className="sr-only">댓글수</span>
                          <em>1</em>
                        </span>
                      </p>
                      <span className="nickname">Aimyonmyon</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p className="title">
                        <strong>트립닷컴 호텔 예약 확인</strong>
                        <span>
                          <span className="sr-only">댓글수</span>
                          <em>12</em>
                        </span>
                      </p>
                      <span className="nickname">따블따블</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="list-item">
                <span className="badge badge--complete">승인완료</span>
                <div className="item-cont">
                  <div className="item-thumb">
                    <img src={KoreantistDariImg} alt="" />
                  </div>
                  <div className="item-info">
                    <Link to="#">
                      <strong className="title">네일동 : 일본여행카페</strong>
                    </Link>
                    <div className="info">
                      <span>운영자</span>
                      <span>23-02-13</span>
                    </div>
                  </div>
                </div>
                <ul className="item-list">
                  <li>
                    <Link to="#">
                      <p className="title">
                        <strong>
                          오사카 여행 교통권 어떻게 하는게 좋을까요~? 오사카
                          여행 교통권 어떻게 하는게 좋을까요~?
                        </strong>
                        <span>
                          <span className="sr-only">댓글수</span>
                          <em>1</em>
                        </span>
                      </p>
                      <span className="nickname">Aimyonmyon</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p className="title">
                        <strong>트립닷컴 호텔 예약 확인</strong>
                        <span>
                          <span className="sr-only">댓글수</span>
                          <em>111</em>
                        </span>
                      </p>
                      <span className="nickname">따블따블</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="list-item">
                <div className="item-cont">
                  <div className="item-thumb">
                    <img src={KoreantistDariImg} alt="" />
                  </div>
                  <div className="item-info">
                    <Link to="#">
                      <strong className="title">네일동 : 일본여행카페</strong>
                    </Link>
                    <div className="info">
                      <span>운영자</span>
                      <span>23-02-13</span>
                    </div>
                  </div>
                </div>
                <ul className="item-list">
                  <li>
                    <Link to="#">
                      <p className="title">
                        <strong>
                          오사카 여행 교통권 어떻게 하는게 좋을까요~?
                        </strong>
                        <span>
                          <span className="sr-only">댓글수</span>
                          <em>1</em>
                        </span>
                      </p>
                      <span className="nickname">Aimyonmyon</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p className="title">
                        <strong>트립닷컴 호텔 예약 확인</strong>
                        <span>
                          <span className="sr-only">댓글수</span>
                          <em>1</em>
                        </span>
                      </p>
                      <span className="nickname">따블따블</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="list-item">
                <div className="item-cont">
                  <div className="item-thumb">
                    <img src={KoreantistDariImg} alt="" />
                  </div>
                  <div className="item-info">
                    <Link to="#">
                      <strong className="title">네일동 : 일본여행카페</strong>
                    </Link>
                    <div className="info">
                      <span>운영자</span>
                      <span>23-02-13</span>
                    </div>
                  </div>
                </div>
                <ul className="item-list">
                  <li>
                    <Link to="#">
                      <p className="title">
                        <strong>
                          오사카 여행 교통권 어떻게 하는게 좋을까요~?
                        </strong>
                        <span>
                          <span className="sr-only">댓글수</span>
                          <em>1</em>
                        </span>
                      </p>
                      <span className="nickname">Aimyonmyon</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p className="title">
                        <strong>트립닷컴 호텔 예약 확인</strong>
                        <span>
                          <span className="sr-only">댓글수</span>
                          <em>1</em>
                        </span>
                      </p>
                      <span className="nickname">따블따블</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  overflow-x: hidden;
  .sub-inner {
    [class*='__title'] {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      border: 0;
      clip: rect(0, 0, 0, 0);
    }
  }
  .c-community {
    display: flex;
    gap: 4rem;
    &__aside {
      .community {
        &__user {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-cont {
            display: flex;
            flex-direction: row;
            flex: 1;
            gap: 2rem;
            .item-thumb {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex: 0 0 6rem;
              width: 6rem;
              height: 6rem;
              border: 1px solid var(--color-gray-eaeaea);
              background: #f9f9f9;
            }
            .item-info {
              display: flex;
              justify-content: space-between;
              flex: 1;
              strong {
                margin-right: 0.5rem;
              }
            }
          }
        }
        &__add {
          a {
            display: flex;
            align-items: center;
            gap: 2rem;
            padding: 2rem;
            border: 1px dashed var(--color-gray-eaeaea);
            background-color: var(--color-gray-f7f7f7);
            transition: all 0.3s ease;
            &:hover {
              border-style: solid;
              border-color: var(--color-black);
              background-color: var(--color-white);
              box-shadow: 3px 3px 10px var(--color-black-rgba-25);
              .item-thumb {
                color: var(--color-black);
                background-color: var(--color-gray-f7f7f7);
              }
            }
            .item-thumb {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex: 0 0 6rem;
              width: 6rem;
              height: 6rem;
              color: var(--color-gray-737373);
              background: var(--color-white);
              transition: all 0.3s ease;
              i {
                font-size: 2.5rem;
              }
            }
            .item-info {
              .title {
                color: var(--color-black);
                font-size: 1.7rem;
                font-weight: 500;
              }
            }
          }
        }
        &__txt {
          display: flex;
          i {
            color: var(--color-red-FF3200);
          }
          p {
            margin-left: 1rem;
          }
        }
      }
    }
    &__content {
      flex: 1;
      .community {
        &__tab {
          display: flex;
          margin-bottom: 3rem;
          li {
            & + li {
              margin-left: -1px;
            }
            &.active {
              a {
                color: var(--color-white);
                border-color: var(--color-main);
                background-color: var(--color-main);
              }
            }
            a {
              display: block;
              padding: 0.8rem 2rem;
              color: var(--color-black);
              font-size: 1.6rem;
              text-align: center;
              border: 1px solid var(--color-gray-d9d9d9);
            }
          }
        }
        &__list {
          display: flex;
          gap: 2rem;
          .list-item {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            border: 1px solid var(--color-gray-eaeaea);
            background: var(--color-white);
            transition: all 0.3s ease;
            &:hover {
              border-color: var(--color-gray-d9d9d9);
              background-color: var(--color-gray-f7f7f7);
            }
            .badge {
              position: absolute;
              top: 2rem;
              right: 2rem;
              color: #fff;
              padding: 0.4rem 1rem;
              border-radius: 10rem;
              box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.1);
              &--waiting {
                background: #ff774d;
              }
              &--complete {
                background: #2abfb8;
                background: #586270;
              }
            }
            .item-cont {
              display: flex;
              flex-direction: row;
              gap: 2rem;
              padding: 2rem 2rem 0;
              .item-thumb {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 8rem;
                width: 8rem;
                height: 8rem;
                border: 1px solid var(--color-gray-eaeaea);
                background: #f9f9f9;
              }
              .item-info {
                .title {
                  color: var(--color-black);
                  font-size: 1.7rem;
                  font-weight: 500;
                }
                .info {
                  span {
                    position: relative;
                    color: var(--color-gray-737373);
                    & + span {
                      margin-left: 1rem;
                      padding-left: 1rem;
                      &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 12px;
                        border-left: 1px solid var(--color-gray-d9d9d9);
                      }
                    }
                  }
                }
              }
            }
            .item-list {
              padding: 2rem;
              border-top: 1px solid var(--color-gray-eaeaea);
              li {
                & + li {
                  margin-top: 0.4rem;
                }
                a {
                  display: flex;
                  justify-content: space-between;
                  gap: 2rem;
                  .title {
                    display: flex;
                    min-width: 1%;
                    strong {
                      display: block;
                      font-weight: 500;
                      ${({ theme }) => theme.text.textOverflow}
                    }
                    > span {
                      line-height: 1.3;
                      em {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 1.6rem;
                        height: 1.6rem;
                        padding: 0 0.4rem;
                        margin-left: 0.4rem;
                        color: var(--color-white);
                        font-size: 1rem;
                        border-radius: 10rem;
                        background: var(--color-black-rgba-25);
                        box-shadow: 2px 3px 3px var(--color-black-rgba-10);
                      }
                    }
                  }
                  .nickname {
                    color: var(--color-gray-737373);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.media.xlMin} {
    .c-community {
      &__aside {
        flex: 0 1 35rem;
        .community {
          &__user {
            margin-bottom: 2rem;
          }
          &__txt {
            margin-top: 2rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.xl} {
    .c-community {
      flex-direction: column;
    }
  }
  @media ${({ theme }) => theme.media.mdMinxl} {
    .c-community {
      &__aside {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        .community {
          &__user,
          &__add {
            flex: 0 0 calc(100% / 2 - 1rem);
          }
          &__txt {
            flex: 1;
          }
          &__user {
            padding: 0 2rem;
            border: 1px solid var(--color-gray-eaeaea);
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-community {
      &__content {
        .community {
          &__list {
            flex-direction: row;
            flex-wrap: wrap;
            .list-item {
              flex: 0 0 calc((100% / 2) - 1rem);
              min-width: 1%;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-community {
      &__aside {
        .community {
          &__user {
            margin-bottom: 2rem;
          }
          &__txt {
            margin-top: 2rem;
          }
        }
      }
      &__content {
        .community {
          &__list {
            flex-direction: column;
          }
        }
      }
    }
  }
`;

CommunityView.defaultProps = {};

export default CommunityView;
