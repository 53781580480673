import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import SiteLayout from './components/templates/SiteLayout';
import routes from './config/router/route';
import { withInitializeProvider } from './hoc/withInitializeProvider';

import theme from './config/utils/theme';

function App() {
  const { pathname } = useLocation(); // 현재 페이지 pathname

  // 현재 경로에 맞는 타이틀 찾기
  useEffect(() => {
    const route = routes.find(p => p.path === pathname);
    if (route && route.title) {
      document.title = route.title;
    } else {
      document.title = '주식회사 바능'; // title 적용
    }
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {/* skipNavigation */}
        <div id="skipNavi">
          <a href="#content">본문 바로가기</a>
          <a href="#gnb">대메뉴 바로가기</a>
        </div>
        <Routes>
          {/* {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <SiteLayout>
                  <route.element />
                </SiteLayout>
              }
            >
              {route?.children?.map(subroute => (
                <Route
                  key={subroute.path}
                  path={subroute.path}
                  element={<subroute.element />}
                />
              ))}
            </Route>
          ))} */}
          {routes.map(route => {
            if (route.isNoneLayout) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.element />}
                />
              );
            }
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <SiteLayout>
                    <route.element />
                  </SiteLayout>
                }
              >
                {route?.children?.map(subroute => (
                  <Route
                    key={subroute.path}
                    path={subroute.path}
                    element={<subroute.element />}
                  />
                ))}
              </Route>
            );
          })}
          <Route path="/" element={<Navigate replace to="/main" />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default withInitializeProvider(App);
