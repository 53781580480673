import React from 'react';
import { useParams } from 'react-router-dom';
import randomstring from 'randomstring';
import UsernameGenerator from 'username-generator';

import ChatBox from '../../molecules/ChatBox';
import { chattingEndPointUrl } from '../../../config/constants';

function Chat() {
  const { roomId } = useParams();
  const randomUserId = randomstring.generate();
  const randomUserName = UsernameGenerator.generateUsername('-');

  const props = {
    roomId,
    wsSourceUrl: chattingEndPointUrl,
    randomUserId,
    randomUserName,
  };

  return <ChatBox {...props} />;
}

export default Chat;
