import React, { useState } from 'react';

import FaqView from './FaqView';

import { useQuery } from '../../../hooks/useQuery';
import {
  SITE_ID,
  BBS_ID,
  SESSION_STORAGE,
  SEARCH_KEY,
} from '../../../config/constants';

/**
 * FAQ 목록
 */
function Faq() {
  const bbsId = BBS_ID['notice'];
  // get SESSION_STORAGE 정보를 가져온다.
  const [preSearchValue] = useState(() => {
    const searchValue = SESSION_STORAGE.getItem(SEARCH_KEY);

    return searchValue && JSON.parse(searchValue).bbsId === bbsId
      ? JSON.parse(searchValue)
      : null;
  });

  // 가져 온 후 정보를 설정 InitialSearchValues에
  const initialSearchValues = {
    pageIndex: preSearchValue?.pageIndex ?? 1,
    pageSize: preSearchValue?.pageSize ?? 10,
    recordCountPerPage: preSearchValue?.recordCountPerPage ?? 10,
    searchCnd: preSearchValue?.searchCnd ?? '1',
    searchWrd: preSearchValue?.searchWrd ?? '',
    bbsId,
    siteId: SITE_ID,
  };

  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchWrd: initialSearchValues.searchWrd,
    },
    items: [
      {
        name: 'searchWrd',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  // 카테고리 목록
  const [categoryOption] = useState({
    initialValues: {
      category: initialSearchValues.category,
    },
    categorys: [
      { name: '전체', key: 'all' },
      { name: '공지', key: 'notice' },
      { name: '보도뉴스', key: 'news' },
    ],
  });

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/article/findAll', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      const newSearch = { ...preSearch, pageIndex, pageSize };
      handleSearchValue(newSearch);
      return newSearch;
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = (event, searchBar) => {
    event.preventDefault();
    const newSearch = { ...searchBar, pageIndex: 1 };
    handleSearchValue(newSearch);
    setSearch(() => {
      return { ...search, ...newSearch };
    });
  };

  const handleCategory = (event, category) => {
    event.preventDefault();
    const newSearch = { ...category, pageIndex: 1 };
    handleSearchValue(newSearch);

    setSearch(() => {
      return { ...search, ...newSearch };
    });
  };

  /**
   * SessionStorage에 페이지 검색 정보를 저장하는 이벤트
   * @search 변경 된 검색 정보
   */
  const handleSearchValue = newSearch => {
    SESSION_STORAGE.setItem(
      SEARCH_KEY,
      JSON.stringify({
        ...search,
        ...newSearch,
      }),
    );
  };

  /**
   * props 세팅
   */
  const props = {
    paging: { ...paging, onPaging: handlePagination },
    notiList: data?.noticeList,
    dataList: data?.resultList,
    searchOption,
    categoryOption,
    onCategory: handleCategory,
    onSearch: handleSearch,
  };

  return <FaqView {...props} />;
}

export default Faq;
