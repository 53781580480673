import React from 'react';

import CommunityView from './CommunityView';

/**
 * 커뮤니티
 * @returns
 */
function Community() {
  return <CommunityView />;
}

export default Community;
