import React from 'react';

import PolicyView from './PolicyView';

/**
 * 개인정보처리방침
 * @returns
 */
function Policy() {
  return <PolicyView />;
}

export default Policy;
