import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Collapse Row Component
 * @param {*} accordian accordian 옵션 적용 여부
 * @param {*} num 클릭 이벤트에서 전달된 선택된 행 번호
 * @param {*} isOpen 외부에서 오픈 여부 핸들링을 위한 Flag
 * @param {*} onToggle 펼치기 기능 이벤트 핸들러
 * @param {object} item 한 행에 대한 오브젝트 데이터
 * @param {*} index map에서 지정된 index 번호
 * @returns Collapse Row
 */
function CollapseRowView(props) {
  const { accordian, num, isOpen, onToggle, item, index } = props;
  // 기본 Collaspe 옵션 시 내부 펼치기 flag 옵션
  const [isInnerOpen, setIsInnerOpen] = useState(() => {
    return num === 0 && index === 0; // 기본 값으로 0번에 대한 펼치기 옵션이 있을 경우 초기값 설정
  });

  // 최초 행 펼치기 옵션 적용시 처리 용
  useEffect(() => {
    if (accordian) {
      if (num === 0) {
        onToggle && onToggle(num, !isOpen);
      }
    }
  }, []);

  /**
   * accordian 상태에 따른 외부/내부 리턴 값 설정
   * @param {*} type accordian
   * @param {*} trueStr 참 인 경우 값
   * @param {*} falseStr 거짓 인 경우 값
   * @returns string
   */
  const replaceAttribute = (type, trueStr, falseStr) => {
    let returnValue = '';

    if (type) {
      returnValue = isOpen ? trueStr : falseStr;
    } else {
      returnValue = isInnerOpen ? trueStr : falseStr;
    }

    return returnValue;
  };

  return (
    <li className={replaceAttribute(accordian, 'active', '')}>
      <div className="faq__list-item--question">
        <button
          type="button"
          onClick={() => {
            if (accordian) {
              onToggle && onToggle(index, !isOpen); // accordian 옵션 적용시 외부 핸들러 사용
            } else {
              setIsInnerOpen(!isInnerOpen); // accordian이 아닌 기본 옵션 적용시 개입
            }
          }}
          aria-expanded={replaceAttribute(accordian, 'true', 'false')}
        >
          <span className="sr-only">질문</span>
          <strong>{item?.nttCn ?? ''}</strong>
        </button>
      </div>
      <div
        className="faq__list-item--answer"
        title={replaceAttribute(accordian, '열림', '닫힘')}
      >
        <div>
          <span className="sr-only">답변</span>
          {item?.nttSj ?? ''}
        </div>
      </div>
    </li>
  );
}

CollapseRowView.propTypes = {
  accordian: PropTypes.bool,
  num: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  item: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
  ),
  index: PropTypes.string,
};

CollapseRowView.defaultProps = {
  accordian: false,
  num: '',
  isOpen: false,
  onToggle: () => {},
  item: {},
  index: '',
};

export default CollapseRowView;
