import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/support/sub-visual.jpg';

import SearchBar from '../../molecules/SearchBar';
import Category from '../../molecules/Category';
import PageInfo from '../../molecules/PageInfo';
import List from '../../molecules/List';
import Pagination from '../../molecules/Pagination';

function BoardView(props) {
  const { paging } = props;

  return (
    <StyledSubContainer
      title="Support"
      subTxt="바능의 다양한 소식을 보실 수 있습니다."
      subVisual={SubVisualImg}
      className="sub-support"
    >
      <div className="inner-container__sub">
        <h3>기본 테이블형 게시판</h3>
        <SearchBar {...props} />
        {/* S::기본 테이블형 게시판 - 리스트 */}
        <article className="c-bbs">
          <Category {...props} />
          <PageInfo {...props} />
          <List {...props} />
          <Pagination {...paging} />
        </article>
        {/* E::기본 테이블형 게시판 - 리스트 */}
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3 {
    text-align: center;
  }
  .c-bbs {
    margin-top: 12rem;
    /* 탭(카테고리&말머리) */
    &__tab {
      margin-bottom: 4rem;
      .tab {
        &__list {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          li {
            &.active {
              a {
                color: var(--color-white);
                border-color: var(--color-main);
                background-color: var(--color-main);
              }
            }
            a {
              padding: 1rem 2rem;
              color: var(--color-black);
              /* font-size: 1.6rem; */
              border: 1px solid var(--color-gray-d9d9d9);
            }
          }
        }
      }
    }
    &__total {
      font-size: 1.7rem;
      em {
        color: var(--color-main);
        font-weight: 500;
      }
    }
    /* 기본 테이블형 게시판 */
  }
  @media ${({ theme }) => theme.media.lgMin} {
    .c-bbs {
      /* 기본 테이블형 게시판 */
      &-board {
        /* 기본 테이블형 게시판 : 리스트 */
        &__list {
          li {
            > div {
              &.num {
                flex: 0 1 12rem;
              }
              &.title {
                flex: 1;
                min-width: 1%;
              }
              &.writer {
                flex: 0 1 16rem;
              }
              &.date {
                flex: 0 1 12rem;
              }
              &.hit {
                flex: 0 1 12rem;
              }
            }
            &:not(.list-head) {
              > div {
                &.title {
                  a {
                    max-width: calc(100% - 18rem);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMinlg} {
    .c-bbs {
      /* 기본 테이블형 게시판 */
      &-board {
        /* 기본 테이블형 게시판 : 리스트 */
        &__list {
          li {
            > div {
              &.num {
                flex: 0 1 8rem;
              }
              &.title {
                flex: 1;
                min-width: 1%;
              }
              &.writer {
                flex: 0 1 12rem;
              }
              &.date {
                flex: 0 1 10rem;
              }
              &.hit {
                flex: 0 1 8rem;
              }
            }
            &:not(.list-head) {
              > div {
                &.title {
                  a {
                    max-width: calc(100% - 12rem);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-bbs {
      margin-top: 12rem;
      /* 기본 테이블형 게시판 */
      &-board {
        /* 기본 테이블형 게시판 : 리스트 */
        &__list {
          li {
            display: flex;
            align-items: center;
            &.empty {
              flex-direction: column;
              justify-content: center;
            }
            &:not(.list-head):not(.empty) {
              > div {
                padding: 1.2rem 0.5rem;
              }
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-bbs {
      margin-top: 6rem;
      &-board {
        /* 기본 테이블형 게시판 : 리스트 */
        &__list {
          li {
            display: block;
            > div {
              &.num {
                display: none;
              }
              &.title {
                display: block;
              }
              &.writer,
              &.date,
              &.hit {
                display: inline-block;
              }
              &.date,
              &.hit {
                position: relative;
                margin-left: 1rem;
                padding-left: 1rem;
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 14px;
                  border-left: 1px solid var(--color-gray-d9d9d9);
                }
              }
            }
            &.empty {
              text-align: center;
            }
            &.list-head {
              display: none;
            }
            &:not(.list-head) {
              padding: 1.2rem 0.5rem;
              > div {
                &.title {
                  a {
                    max-width: calc(100% - 12rem);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

BoardView.propTypes = {
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  notiList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  dataList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  categoryOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onCategory: PropTypes.func,
  onSearch: PropTypes.func,
  onMoveNotiDetail: PropTypes.func,
  onMoveDetail: PropTypes.func,
};

BoardView.defaultProps = {
  paging: {},
  notiList: [],
  dataList: [],
  searchOption: {},
  categoryOption: {},
  onCategory: () => {},
  onSearch: () => {},
  onMoveNotiDetail: () => {},
  onMoveDetail: () => {},
};

export default BoardView;
