import React from 'react';

import SurveyRegistView from './SurveyRegistView';

/**
 * 설문조사 - 등록페이지
 * @returns
 */
function SurveyRegist() {
  return <SurveyRegistView />;
}

export default SurveyRegist;
