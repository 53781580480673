import React from 'react';
import PropTypes from 'prop-types';

function PageInfoView({ paging }) {
  return (
    <>
      Total : <em>{paging?.totalPageCount ?? '1'}</em> (
      {`${paging?.currentPageNo ?? '1'} / ${paging?.lastPageNo ?? '1'} page`})
    </>
  );
}

PageInfoView.propTypes = {
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
};

PageInfoView.defaultProps = {
  paging: {},
};

export default PageInfoView;
