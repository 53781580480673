/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';

function JoinCertifyView() {
  return (
    <StyledSubContainer
      isVisible
      title="Member"
      subTxt=" 홈페이지에 오신 것을 환영합니다."
      subVisual={SubVisualImg}
      className="sub-member"
    >
      <article className="c-join-certify">
        <div className="inner-container__sub">
          <h3>회원가입</h3>
          <ol className="agree-step">
            <li>
              <span>Step1</span>
              <strong>약관동의</strong>
            </li>
            <li className="active">
              <span>Step2</span>
              <strong>본인 인증</strong>
            </li>
            <li>
              <span>Step3</span>
              <strong>회원정보 입력</strong>
            </li>
            <li>
              <span>Step4</span>
              <strong>가입 완료</strong>
            </li>
          </ol>
          <h4>본인 인증</h4>
          <div className="c-certify">
            <div className="c-certify__txt">
              <p>
                처음 로그인하시는 분은 먼저 본인 인증을 하시기 바랍니다.
                <br />
                휴대폰, 아이핀 본인인증 후 서비스를 이용할 수 있습니다.
              </p>
            </div>
            <ul className="c-certify__list">
              <li>
                <Link to="#">
                  <i className="ri-lock-2-line" />
                  <span>간편 인증</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="ri-phone-lock-line" />
                  <span>휴대폰 인증</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="ri-file-shield-2-line" />
                  <span>공동인증서 인증</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="ri-mail-lock-line" />
                  <span>이메일 인증</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  .agree-step {
    /* display: table;
    table-layout: fixed; */
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 4rem;
    > li {
      position: relative;
      counter-increment: stepNum;
      flex: 1;
      /* display: table-cell; */
      text-align: center;
      color: #ff6347;
      &::before {
        content: '✓';
        display: block;
        margin: 0 auto 4px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-weight: bold;
        border: 2px solid #ff6347;
        border-radius: 50%;
        background-color: #fff;
      }
      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #ff6347;
        position: absolute;
        top: 20px;
        left: 50%;
        z-index: -1;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.active {
        &:before {
          content: counter(stepNum);
          font-family: inherit;
          font-weight: 700;
          color: #fff;
          background-color: #ff6347;
          border-color: #ff6347;
        }
        &:after {
          background-color: #ededed;
        }
        ~ li {
          color: #808080;
          &:before {
            content: counter(stepNum);
            font-family: inherit;
            font-weight: 700;
            background-color: #ededed;
            border-color: #ededed;
          }
          &:after {
            background-color: #ededed;
          }
        }
      }
      span {
        text-transform: uppercase;
      }
      strong {
        display: block;
        font-weight: 500;
      }
    }
  }
  .c-certify {
    padding: 5rem 3.2rem 6rem;
    border: 1px solid var(--color-gray-d9d9d9);
    &__txt {
      text-align: center;
      margin-bottom: 3.2rem;
      p {
        font-size: 1.7rem;
      }
    }
    &__list {
      display: flex;
      justify-content: center;
      gap: 30px;
      li {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 20rem;
          height: 20rem;
          color: var(--color-black);
          font-size: 1.7rem;
          border: 1px solid var(--color-gray-d9d9d9);
          transition: all 0.4s;
          &:hover {
            color: var(--color-white);
            background-color: var(--color-black);
            i {
              color: var(--color-black);
              background-color: var(--color-white);
            }
          }
          i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6.4rem;
            height: 6.4rem;
            margin-bottom: 2rem;
            font-size: 2.8rem;
            border-radius: 50%;
            background: var(--color-gray-f5f5f5);
            transition: all 0.4s;
          }
        }
      }
    }
  }
`;

export default JoinCertifyView;
