import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const selectItem = (option, initialValues, searchCnd, onChangeCnd) => {
  const { name, placeholder, codes } = option;
  return (
    <select
      name={name}
      id={name}
      placeholder={placeholder}
      onChange={onChangeCnd}
    >
      {codes.map(code => {
        const { value, text } = code;
        return (
          <option
            key={value}
            value={value}
            selected={value === initialValues?.searchCnd ?? ''}
          >
            {text}
          </option>
        );
      })}
    </select>
  );
};

const inputItem = (option, initialValues, searchWrd, onChangeWrd) => {
  const { name, placeholder } = option;
  return (
    <input
      type="text"
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={onChangeWrd}
      defaultValue={searchWrd || initialValues.searchWrd}
    />
  );
};

const hiddenItem = option => {
  const { name } = option;
  return <input type="hidden" id={name} name={name} />;
};

function SearchBarView({
  searchCnd,
  searchWrd,
  searchOption,
  onSearchTrigger,
  onChangeCnd,
  onChangeWrd,
}) {
  const { initialValues, items } = searchOption;

  return (
    <StyledSearch className="c-search">
      <form id="searchForm" name="searchForm" onSubmit={onSearchTrigger}>
        <fieldset>
          <legend>검색</legend>
          <div className="c-search__input">
            {items.map(option => {
              const { type } = option;
              if (type === 'select')
                return selectItem(
                  option,
                  initialValues,
                  searchCnd,
                  onChangeCnd,
                );
              if (type === 'input')
                return inputItem(option, initialValues, searchWrd, onChangeWrd);
              if (type === 'hidden') return hiddenItem(option);
              return null;
            })}
            <button type="submit" className="btn-search">
              <i className="ri-search-line" />
              <span className="sr-only">검색</span>
            </button>
          </div>
        </fieldset>
      </form>
    </StyledSearch>
  );
}

const StyledSearch = styled.div`
  .c-search {
    &__input {
      display: flex;
      align-items: center;
      max-width: 48rem;
      height: 50px;
      margin: 0px auto;
      select {
        width: 12rem;
        height: 4.8rem;
        padding: 0;
        line-height: 4.8rem;
        font-size: 1.7rem;
        border-bottom: 2px solid var(--color-black);
        & + input {
          margin-left: 1.6rem;
        }
      }
      input {
        flex: 1;
        height: 4.8rem;
        line-height: 4.8rem;
        font-size: 1.8rem;
        border: 0;
        padding: 0 3rem 0 0;
        border-bottom: 2px solid var(--color-black);
        background: transparent;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.2rem;
        height: 4.2rem;
        margin-left: 1rem;
        color: var(--color-white);
        font-size: 2rem;
        border-radius: 50%;
        background: var(--color-black);
      }
    }
  }
`;

SearchBarView.propTypes = {
  searchCnd: PropTypes.string.isRequired,
  searchWrd: PropTypes.string.isRequired,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ).isRequired,
  onSearchTrigger: PropTypes.func.isRequired,
  onChangeCnd: PropTypes.func.isRequired,
  onChangeWrd: PropTypes.func.isRequired,
};

export default SearchBarView;
