/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import { SESSION_STORAGE, SEARCH_KEY } from '../../../config/constants';
import Button from '../../atoms/Button';

import KoreantistIcon from '../../atoms/Icon/KoreantistIcon';
import LogoImg from '../../../assets/img/common/logo.png';

/**
 * Footer
 * @returns
 */
function SiteFooterView() {
  const [mousePos, setMousePos] = useState({});
  const [isShowChat, setIsShowChat] = useState(false);
  const [isFold, setFold] = useState(false);
  const handleStorageRemove = () => {
    SESSION_STORAGE.removeItem(SEARCH_KEY);
  };
  const handleMenuClick = () => {
    setFold(!isFold);
  };

  // scrollToTop
  const [showButton, setShowButton] = useState(false);
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', handleShowButton);
    return () => {
      window.removeEventListener('scroll', handleShowButton);
    };
  }, []);

  const handleDragStart = () => {};
  const handleDragStop = () => {};

  const dragHandlers = { onStart: handleDragStart, onStop: handleDragStop };
  return (
    <StyledFooter className="site-layout__footer">
      <div className="c-footer">
        <div className="c-footer__nav">
          <ul className="nav">
            <li>
              <Link
                to="/aboutUs"
                onClick={() => {
                  handleStorageRemove();
                  handleMenuClick();
                }}
              >
                회사소개
              </Link>
            </li>
            <li>
              <Link
                to="/playground"
                onClick={() => {
                  handleStorageRemove();
                  handleMenuClick();
                }}
              >
                플레이그라운드
              </Link>
            </li>
            <li>
              <Link
                to="/catchfun"
                onClick={() => {
                  handleStorageRemove();
                  handleMenuClick();
                }}
              >
                캐치펀
              </Link>
            </li>
            <li>
              <Link
                to="/ourWork"
                onClick={() => {
                  handleStorageRemove();
                  handleMenuClick();
                }}
              >
                Our Work
              </Link>
            </li>
            <li>
              <Link
                to="/contactUs"
                onClick={() => {
                  handleStorageRemove();
                  handleMenuClick();
                }}
              >
                문의하기
              </Link>
            </li>
            <li>
              <Link
                to="/employment"
                onClick={() => {
                  handleStorageRemove();
                  handleMenuClick();
                }}
              >
                채용안내
              </Link>
            </li>
            <li>
              <Link
                to="/policy"
                onClick={() => {
                  handleStorageRemove();
                  handleMenuClick();
                }}
              >
                개인정보처리방침
              </Link>
            </li>
            <li>
              <Link
                to="/location"
                onClick={() => {
                  handleStorageRemove();
                  handleMenuClick();
                }}
              >
                오시는길
              </Link>
            </li>
          </ul>
          <div className="util">
            <a
              href="https://www.instagram.com/koreantist/"
              target="_blank"
              title="새창열림"
              rel="noopener noreferrer"
            >
              <KoreantistIcon />
              <i className="ri-instagram-line" />
              <span className="sr-only">코리안티스트 인스타그램</span>
            </a>
          </div>
        </div>
        <div className="c-footer__info">
          <address>
            <p>
              <a href="tel:1800-2398">
                {/* <i className="ri-phone-fill" /> */}
                T. 1800-2398
              </a>
              <span>F. 02-6442-2398 </span>
              <span>E. admin@reacting.kr</span>
            </p>
            <p>
              A. 경기 성남시 분당구 성남대로 295 (정자동, 대림아크로텔) C동
              218호
            </p>
            <p className="copy">
              Copyright © Baneung Inc. All rights reserved.
            </p>
          </address>
        </div>
        {showButton && (
          <div className="scroll-top">
            <button type="button" id="top" onClick={scrollToTop}>
              <i className="ri-arrow-up-line" />
              <span className="sr-only">상단으로 바로가기</span>
            </button>
          </div>
        )}
        <div className="scroll-chat">
          <button
            type="button"
            id="chat"
            onClick={event => {
              // setMousePos({ x: 100, y: event.screenY - event.pageY });
              // setIsShowChat(!isShowChat);
              alert('작업 중입니다.');
            }}
          >
            <i className="ri-chat-smile-3-line" />
            <span className="sr-only">채팅하기</span>
          </button>
        </div>
      </div>
      {isShowChat && (
        <Draggable {...dragHandlers}>
          <ChatBox position={mousePos}>
            <div className="c-chatbox">
              <div className="c-chatbox__header">
                <strong>
                  <i className="ri-message-3-fill" /> 바능에게 문의하기
                </strong>
                <Button
                  type="button"
                  onClick={() => {
                    setIsShowChat(false);
                  }}
                  variant="link"
                  size="xs"
                >
                  <i className="ri-close-line" />
                </Button>
              </div>
              <div className="c-chatbox__body">
                <ul className="chatbox-list">
                  <li className="list-item--right">
                    <div className="item-cont">
                      <div className="item-text">
                        <div className="item-message">
                          테스트 쪽지입니다. right
                        </div>
                        <div className="item-time">
                          <i className="ri-time-line" /> <time>2017-07-11</time>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-item--left">
                    <div className="item-user">
                      <img src={LogoImg} alt="바능" />
                    </div>
                    <div className="item-cont">
                      <span>바능</span>
                      <div className="item-text">
                        <div className="item-message">
                          테스트 쪽지입니다. left
                        </div>
                        <div className="item-time">
                          <i className="ri-time-line" /> <time>2017-07-11</time>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-item--right">
                    <div className="item-cont">
                      <div className="item-text">
                        <div className="item-message">
                          테스트 쪽지입니다. 테스트 쪽지입니다.테스트
                          쪽지입니다. 테스트 쪽지입니다.
                        </div>
                        <div className="item-time">
                          <i className="ri-time-line" /> <time>2017-07-11</time>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-item--left">
                    <div className="item-user">
                      <img src={LogoImg} alt="바능" />
                    </div>
                    <div className="item-cont">
                      <span>바능</span>
                      <div className="item-text">
                        <div className="item-message">
                          테스트 쪽지입니다. 테스트 쪽지입니다.테스트
                          쪽지입니다. 테스트 쪽지입니다.
                        </div>
                        <div className="item-time">
                          <i className="ri-time-line" /> <time>2017-07-11</time>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-item--left">
                    <div className="item-user">
                      <img src={LogoImg} alt="바능" />
                    </div>
                    <div className="item-cont">
                      <span>바능</span>
                      <div className="item-text">
                        <div className="item-message">
                          테스트 쪽지입니다. left
                        </div>
                        <div className="item-time">
                          <i className="ri-time-line" /> <time>2017-07-11</time>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="c-chatbox__footer">
                <div className="item-input">
                  <label htmlFor="message" className="sr-only">
                    메세지 입력
                  </label>
                  <input
                    type="text"
                    id="message"
                    name="message"
                    placeholder="메세지를 입력해주세요."
                  />
                </div>
                <Button type="button" variant="default" size="sm">
                  <i className="ri-send-plane-fill" />
                  <span className="sr-only">전송</span>
                </Button>
              </div>
            </div>
          </ChatBox>
        </Draggable>
      )}
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  text-align: center;
  /* padding: 4rem 0; */
  background: var(--color-gray-323232);
  .c-footer {
    position: relative;
    max-width: var(--cantainer-width);
    margin: 0 auto;
    padding: 0 1.6rem;
    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-white-rgba-25);
      .nav {
        li {
          display: inline-block;
          font-weight: 500;
          text-transform: uppercase;
          a {
            position: relative;
            color: var(--color-white);
            &::before {
              content: '';
              position: absolute;
              bottom: -2px;
              left: 0;
              display: inline-block;
              width: 0;
              height: 1px;
              background: var(--color-white-rgba-50);
              transition: all 0.3s;
            }
            &:hover {
              &::before {
                width: 100%;
              }
            }
          }
        }
      }
      .util {
        a {
          display: flex;
          align-items: center;
          color: var(--color-white);
          font-size: 2.4rem;
          i {
            margin-left: 1.4rem;
          }
        }
      }
    }
    &__info {
      padding: 5rem 0;
      color: var(--color-gray-969696);
      span {
        color: var(--color-white-rgba-85);
      }
      span:not(:last-child) {
        margin-right: 2rem;
      }
      a {
        position: relative;
        color: var(--color-white-rgba-85);
        font-family: var(--font-Roboto);
        margin-right: 2rem;
        &::before {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          display: inline-block;
          width: 0;
          height: 1px;
          background: var(--color-white-rgba-50);
          transition: all 0.3s;
        }
        &:hover {
          color: var(--color-white);
          &::before {
            width: 100%;
          }
        }
      }
      .copy {
        margin-top: 0.5rem;
        color: var(--color-white-rgba-25);
        font-size: 1.2rem;
        font-family: var(--font-GmarketSans);
      }
    }
    .scroll-top {
      position: fixed;
      /* &:empty {
        opacity: 0;
      } 
      &:not(:empty) {*/
      animation: scroll-Up 0.4s both;
      @-webkit-keyframes scroll-Up {
        0% {
          visibility: visible;
          opacity: 0;
          transform: translate3d(0, 30%, 0);
        }
        100% {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      @keyframes scroll-Up {
        0% {
          visibility: visible;
          opacity: 0;
          transform: translate3d(0, 30%, 0);
        }
        100% {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      /* } */
      button {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background: var(--color-main);
        background: var(--color-gray-3c3c3c);
        box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
        i {
          color: var(--color-white);
          font-size: 2.6rem;
        }
      }
    }
    .scroll-chat {
      position: fixed;
      button {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background: var(--color-main);
        box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
        i {
          color: var(--color-white);
          font-size: 2.6rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.xlMin} {
    .c-footer {
      &__nav {
        .nav {
          li {
            & + li {
              margin-left: 7.4rem;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMinxl} {
    .c-footer {
      &__nav {
        .nav {
          li {
            & + li {
              margin-left: 4.2rem;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    .c-footer {
      &__nav {
        flex-direction: column;
        justify-content: center;
        .nav {
          margin-bottom: 1rem;
          li {
            margin: 0 1.5rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-footer {
      &__nav {
        padding-top: 3.5rem;
        padding-bottom: 3rem;
      }
      .scroll-top {
        right: 3rem;
        bottom: 11rem;
      }
      .scroll-chat {
        right: 3rem;
        bottom: 3rem;
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-footer {
      &__nav {
        padding-top: 3rem;
        padding-bottom: 2rem;
        .util {
          a {
            svg {
              width: 14rem;
            }
          }
        }
      }
      .scroll-top {
        right: 2rem;
        bottom: 10rem;
        z-index: 1;
      }
      .scroll-chat {
        right: 2rem;
        bottom: 2rem;
        z-index: 1;
      }
    }
  }
`;

const ChatBox = styled.div`
  position: fixed;
  right: 10rem;
  bottom: 3rem;
  /* bottom: ${props => props.position.y}px;
  right: ${props => props.position.x}px; */
  width: 42rem;
  height: 60rem;
  border-radius: 1.6rem;
  background: var(--color-white);
  box-shadow: rgba(255, 255, 255, 0.12) 0px 0px 2px 0px inset,
    rgba(0, 0, 0, 0.05) 0px 0px 2px 1px, rgba(0, 0, 0, 0.3) 0px 12px 60px;
  overflow: hidden;
  z-index: 999;
  .c-chatbox {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 5.6rem;
      padding-left: 1.6rem;
      border-bottom: 1px solid var(--color-gray-eaeaea);
      background: var(--color-gray-f5f5f5);
      strong {
        font-size: 1.6rem;
        font-weight: 500;
        i {
          font-size: 1.8rem;
        }
      }
      button {
        font-size: 2.4rem;
      }
    }
    &__body {
      flex: 1;
      padding: 1.6rem;
      text-align: left;
      overflow-y: auto;
      /* background: var(--color-gray-f5f5f5); */
      .chatbox-list {
        li {
          display: flex;
          align-items: flex-end;
          & + li {
            margin-top: 1rem;
          }
          &[class*='-left'] {
            .item-cont {
              align-items: flex-start;
              .item-text {
                background: #eef1f4;
                &::before {
                  left: -8px;
                  border-color: transparent #eef1f4 #eef1f4 transparent;
                }
              }
            }
          }
          &[class*='-right'] {
            .item-cont {
              align-items: flex-end;
              .item-text {
                color: var(--color-white);
                text-align: right;
                background: linear-gradient(
                  45deg,
                  var(--color-2abfb8-rgba-85),
                  var(--color-main)
                );
                &::before {
                  right: -8px;
                  border-color: var(--color-main) transparent transparent
                    var(--color-main);
                }
              }
            }
          }
          .item-user {
            img {
              width: 2.2rem;
            }
          }
          .item-cont {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            flex: 1;
            span {
              margin-left: 12px;
              font-size: 1.3rem;
              font-weight: 300;
            }
            .item-text {
              position: relative;
              max-width: 80%;
              padding: 0.4rem 1rem;
              margin-left: 1.2rem;
              font-weight: 300;
              border-radius: 4px;
              box-shadow: 1px 1px 1px var(--color-black-rgba-10);
              &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                bottom: 6px;
                border: 4px solid;
              }
              .item-time {
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }
    &__footer {
      display: flex;
      height: 5rem;
      border-top: 1px solid var(--color-gray-eaeaea);
      background: green;
      .item-input {
        display: flex;
        flex: 1;
        input {
          flex: 1;
          border: 0;
        }
      }
      button {
        i {
          font-size: 2rem;
        }
      }
    }
  }
`;

export default SiteFooterView;
