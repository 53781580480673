import React from 'react';

import JoinAgreeView from './JoinAgreeView';

/**
 * 로그인 - 약관동의
 * @returns
 */
function JoinAgree() {
  return <JoinAgreeView />;
}

export default JoinAgree;
