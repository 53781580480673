import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import TestSampleView from './TestSampleView';
import { useQuery } from '../../../hooks/useQuery';

/**
 * 회사소개
 * @returns
 */
function TestSample() {
  const { id: cntntsId } = useParams();
  // const location = useLocation();
  // const navigate = useNavigate();
  const [detail, setDetail] = useState();

  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery(
    '/api/contents/find',
    { cntntsId },
    {
      cacheTime: 0,
      enabled: !detail,
      select: res => {
        return res.result;
      },
    },
  );

  const props = {
    data,
  };

  useEffect(() => {
    if (data) {
      const head = document?.head;
      const link = document.createElement('link');

      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = `${process.env.REACT_APP_STORAGE_URI}${data.cssUrl}`;

      head.appendChild(link);

      return () => {
        head.removeChild(link);
      };
    }
  }, [data]);
  return <TestSampleView {...props} />;
}

export default TestSample;
