import React from 'react';

import LayerPopupView from './LayerPopupView';

/**
 * Layer 팝업 컴포넌트
 * @param {*} props 전달 인자
 * @returns Layer 팝업
 */
export default function LayerPopup(props) {
  return <LayerPopupView {...props} />;
}
