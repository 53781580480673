import React from 'react';

import JoinRegistView from './JoinRegistView';

/**
 * 로그인 - 회원정보 입력
 * @returns
 */
function JoinRegist() {
  return <JoinRegistView />;
}

export default JoinRegist;
