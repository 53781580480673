import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CollapseRow from './CollapseRow';

/**
 * Collapse List component
 * @param {boolean} accordian 옵션 flag
 * @param {boolean} firstRowOpen 첫행 펼치기 옵션 flag
 * @param {Array} faqs 데이터즈
 * @returns Collapse List
 */
function CollapseView(props) {
  const { accordian, dataList, firstRowOpen } = props;
  // 클릭 이벤트로 지정된 index를 받는 state
  const [num, setNum] = useState(firstRowOpen ? 0 : 9999); // 첫행 열기 적용시 0으로 설정(기본 9999)
  const [isOn, setIsOn] = useState(false); // Accordian 옵션 적용 시 확인 용 state
  const [isOpen, setIsOpen] = useState(false); // Accordian 옵션 적용 시 열기/닫기 용 flag state

  /**
   * 열기/닫기를 실행하는 이벤트 핸들러
   * @param {int} index 선택된 행
   * @param {boolean} flag 열기/닫기 flag
   */
  const handleToggle = (index, flag) => {
    if (accordian) {
      setIsOpen(false); // 1. 전체를 한번 닫음
      setNum(index); // 2. 지정된 인덱스 num에 적용
      setIsOn(flag); // 3. 열기/닫기 적용
    }
  };

  return (
    <StyledFaqUl className="c-bbs-faq c-bbs-faq__list">
      {dataList && dataList.length > 0 ? (
        dataList?.map((item, index) => {
          const props = {
            accordian,
            num,
            item,
            // Accordian 옵션 인 경우 열기/닫기를 적용하기 위한 조건
            // 선택 된 행 = 현재 그려지는 행 index && 열기가 on 일시만 true/ 나머지 행은 false
            isOpen: num === index && isOn,
            index,
            setIsOpen,
            onToggle: handleToggle,
          };

          return <CollapseRow {...props} />;
        })
      ) : (
        <li className="empty">
          <i className="ri-edit-2-line" />
          <p>등록된 게시글이 없습니다.</p>
        </li>
      )}
    </StyledFaqUl>
  );
}

const StyledFaqUl = styled.ul`
  &.c-bbs {
    &__total {
      font-size: 1.7rem;
      em {
        color: var(--color-main);
        font-weight: 500;
      }
    }
    /* Faq 게시판 */
    &-faq {
      /* Faq 게시판 : 리스트 */
      &__list {
        margin-top: 2rem;
        border-top: 2px solid var(--color-black);
        li {
          border-bottom: 1px solid var(--color-gray-d9d9d9);
          &.active {
            [class*='--question'] {
              button,
              a {
                &::after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 42%;
                  right: 1.5rem;
                  width: 0.8rem;
                  height: 0.8rem;
                  border-left: 2px solid var(--color-gray-737373);
                  border-bottom: 2px solid var(--color-gray-737373);
                  transform: rotate(135deg) translateY(-50%);
                  vertical-align: top;
                  transition: all 0.3s ease 0s;
                }
              }
            }
            [class*='--answer'] {
              display: flex;
              align-items: center;
            }
          }
          &.empty {
            width: 100%;
            padding: 8rem 0;
            font-size: 1.7rem;
            text-align: center;
            i {
              color: var(--color-gray-d9d9d9);
              font-size: 4.8rem;
            }
          }
          [class*='--question'] {
            button,
            a {
              position: relative;
              display: flex;
              /* align-items: center; */
              width: 100%;
              padding: 1.6rem 2rem;
              color: var(--color-black);
              transition: 0.3s;
              background: transparent;
              &::before {
                content: 'Q';
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 1.4rem;
                left: 2rem;
                width: 2.4rem;
                height: 2.4rem;
                color: var(--color-white);
                font-size: 1.4rem;
                border-radius: 0.4rem;
                background: var(--color-main);
              }
              &::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 50%;
                right: 1rem;
                width: 0.8rem;
                height: 0.8rem;
                border-left: 2px solid var(--color-gray-737373);
                border-bottom: 2px solid var(--color-gray-737373);
                transform: rotate(-45deg) translateY(-50%);
                vertical-align: top;
                transition: all 0.3s ease 0s;
              }
              strong {
                flex: 1;
                padding: 0 4rem;
                font-size: 1.7rem;
                font-weight: 500;
                text-align: left;
              }
            }
          }
          [class*='--answer'] {
            position: relative;
            display: none;
            padding: 2rem;
            background: var(--color-gray-f5f5f5);
            &::before {
              content: 'A';
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 1.9rem;
              left: 2rem;
              width: 2.4rem;
              height: 2.4rem;
              color: var(--color-white);
              font-size: 1.4rem;
              border-radius: 0.4rem;
              background: var(--color-black);
            }
            > div {
              flex: 1;
              padding-left: 4rem;
            }
          }
        }
      }
    }
  }
`;

CollapseView.propTypes = {
  accordian: PropTypes.bool,
  firstRowOpen: PropTypes.bool,
  dataList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

CollapseView.defaultProps = {
  accordian: false,
  firstRowOpen: false,
  dataList: [],
};

export default CollapseView;
