/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SESSION_STORAGE, SEARCH_KEY } from '../../../config/constants';

import LogoWImg from '../../../assets/img/common/logo-w.png';
import LogoImg from '../../../assets/img/common/logo.png';
import KoreantistIcon from '../../atoms/Icon/KoreantistIcon';

/**
 * Header
 * @returns
 */
function SiteHeaderView() {
  const [isTop, setIsTop] = useState(true);
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setIsTop(false) : setIsTop(true);
  };
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, []);

  const [isFold, setFold] = useState(false);

  const handleStorageRemove = () => {
    SESSION_STORAGE.removeItem(SEARCH_KEY);
  };

  const handleMenuClick = () => {
    setFold(!isFold);
  };

  return (
    <StyledHeader
      className={`site-layout__header${isFold ? ' nav-active' : ''}${
        isTop ? '' : ' is-scroll'
      }`}
    >
      <div className="c-header">
        <h1>
          <Link
            to="/main"
            onClick={() => {
              setFold(false);
              handleStorageRemove();
            }}
          >
            <span className="sr-only">ㅂㄴ React</span>
          </Link>
        </h1>
        <nav id="gnb" className="gnb">
          <h2 className="sr-only">주메뉴</h2>
          <button
            type="button"
            className="btn-menu"
            onClick={handleMenuClick}
            aria-label="Menu"
          >
            <span className="btn-menu__line btn-menu__line--left" />
            <span className="btn-menu__line" />
            <span className="btn-menu__line btn-menu__line--right" />
          </button>
          <div className="nav">
            <div className="nav__content">
              <ul className="nav__list--depth1">
                <li className="nav__list-item">
                  <Link
                    to="/aboutUs"
                    onClick={() => {
                      handleStorageRemove();
                      handleMenuClick();
                    }}
                    data-hover="About"
                  >
                    <span>About</span>
                  </Link>
                  <ul className="nav__list--depth2">
                    <li>
                      <Link
                        to="/aboutUs"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>회사소개</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contents/CNT_0000000000000353"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>회사연혁</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contents/CNT_0000000000000354"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>조직도</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav__list-item">
                  <Link
                    to="/playground"
                    onClick={() => {
                      handleStorageRemove();
                      handleMenuClick();
                    }}
                  >
                    <span>Product</span>
                  </Link>
                  <ul className="nav__list--depth2">
                    <li>
                      <Link
                        to="/playground"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>플레이그라운드</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/catchfun"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>캐치펀</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav__list-item">
                  <Link
                    to="/ourWork"
                    onClick={() => {
                      handleStorageRemove();
                      handleMenuClick();
                    }}
                  >
                    <span>Our work</span>
                  </Link>
                </li>
                <li className="nav__list-item">
                  <Link
                    to="/notice"
                    onClick={() => {
                      handleStorageRemove();
                      handleMenuClick();
                    }}
                  >
                    <span>Support</span>
                  </Link>
                  <ul className="nav__list--depth2">
                    <li>
                      <Link
                        to="/notice"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>NOTICE</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contactUs"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>문의하기</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/employment"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>채용안내</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/location"
                        onClick={() => {
                          handleStorageRemove();
                          handleMenuClick();
                        }}
                      >
                        <span>오시는길</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="util">
              <a
                href="https://www.instagram.com/koreantist/"
                target="_blank"
                title="새창열림"
                rel="noopener noreferrer"
              >
                <KoreantistIcon />
                <i className="ri-instagram-line" />
                <span className="sr-only">코리안티스트 인스타그램</span>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  /* height: 12rem; */
  background: transparent;
  transition: opacity 0.8s ease, all 0.3s ease;
  opacity: 1;
  z-index: 10;
  &.nav-active {
    height: 100%;
    background: var(--color-main);
    .btn-menu {
      margin-top: -10px;
      &__line {
        margin-bottom: -6px;
        transform: translateX(0px) rotate(-45deg);
        background-color: var(--color-white);
      }
      &__line--left {
        transform: translateX(1px) rotate(45deg);
      }
      &__line--right {
        transform: translateX(-2px) rotate(45deg);
      }
    }
    .nav {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      visibility: visible;
      &:before,
      &:after {
        transform: translateX(0%) translateY(0%);
      }
      &:after {
        transition-delay: 0.1s;
      }
      &:before {
        transition-delay: 0s;
      }
      &__list-item {
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 0.3s ease, transform 0.3s ease;
      }
      .util {
        a {
          opacity: 1;
          filter: blur(0);
          transition: opacity 0.3s ease, filter 0.3s ease;
        }
      }
    }
  }
  h1 {
    a {
      display: inline-block;
      width: 44px;
      height: 44px;
      background-image: url(${LogoWImg});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 44px 44px;
    }
  }
  .c-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--cantainer-width);
    margin: 0 auto;
    transition: all 0.3s ease;
    &__util {
      ul {
        display: flex;
        li {
          font-size: 2rem;
          padding: 0 1rem;
        }
      }
    }
  }
  .btn-menu {
    position: relative;
    height: 44px;
    width: 44px;
    background: transparent;
    &__line {
      display: block;
      height: 3px;
      margin: 10px 0;
      transition: transform 0.2s ease, background-color 0.5s ease;
      background: var(--color-white);
    }
    &__line--left {
      width: 26px;
    }
    &__line--right {
      width: 26px;
      margin-left: auto;
    }
  }
  .nav {
    visibility: hidden;
    &:before,
    &:after {
      content: '';
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: rgba(#eaeaea, 0.2);
      z-index: -1;
      transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8 + s;
      transform: translateX(0%) translateY(-100%);
    }
    &:after {
      background: rgba(var(--color-white), 1);
      transition-delay: 0s;
    }
    &:before {
      transition-delay: 0.1s;
    }
    &__content {
      position: fixed;
      max-width: calc(var(--cantainer-width) - 16rem);
      width: calc(100% - 3.2rem);
    }
    &__list--depth1,
    &__list--depth2 {
      > li {
        position: relative;
        a {
          display: block;
          color: var(--color-white);
        }
      }
    }
    &__list--depth1 {
      display: flex;
      justify-content: space-around;
      > li {
        > a {
          font-size: 4rem;
          font-weight: 700;
          text-transform: uppercase;
          > span {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: -20px;
              display: inline-block;
              width: 18px;
              height: 1px;
              transform: rotate(225deg);
              background: var(--color-white-rgba-50);
            }
          }
        }
      }
    }
    &__list--depth2 {
      margin-top: 5rem;
      > li {
        a {
          padding: 1rem 0;
          font-size: 2.2rem;
          font-weight: 500;
          > span {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              bottom: -2px;
              left: 0;
              display: inline-block;
              width: 0;
              height: 1px;
              background: var(--color-white-rgba-50);
              transition: all 0.3s;
            }
          }
          &:hover {
            > span {
              position: relative;
              &::before {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &__list-item {
      position: relative;
      display: inline-block;
      width: calc(100% / 4);
      opacity: 0;
      transform: translate(0%, 100%);
      transition-delay: 0.8 + s;
      transition: opacity 0.2s ease, transform 0.3s ease;
      vertical-align: top;
    }
    .util {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: calc(var(--cantainer-width) - 16rem);
      width: calc(100% - 3.2rem);
      text-align: right;
      a {
        display: inline-flex;
        align-items: center;
        color: var(--color-white);
        font-size: 2.4rem;
        transition-delay: 0.8 + s;
        opacity: 0;
        filter: blur(12px);
        transition: opacity 0.2s ease, filter 0.3s ease;
        i {
          margin-left: 1.6rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    &.is-scroll {
      transform: translateY(-13rem);
      transition: opacity 0.3s ease, all 0.8s ease;
      &.nav-active {
        transform: translateY(0);
      }
    }
    .c-header {
      padding: 2.4rem;
    }
    .nav {
      &__content {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &__list--depth1 {
        > li {
          & + li {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 1px;
              background: var(--color-white-rgba-25);
            }
          }
        }
      }
      &__list-item {
        text-align: center;
      }
      .util {
        bottom: 15rem;
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    &.is-scroll {
      h1 {
        a {
          background-image: url(${LogoImg});
        }
      }
      .c-header {
        background: var(--color-white);
      }
      .btn-menu {
        &__line {
          background: var(--color-main);
        }
      }
    }
    .c-header {
      padding: 1rem 1.6rem;
      border-bottom: 1px solid var(--color-white-rgba-25);
      box-shadow: 0px 8px 6px 0px var(--color-black-rgba-5);
    }
    .nav {
      &__content {
        top: 12rem;
        left: 50%;
        transform: translateX(-50%);
      }
      &__list--depth1 {
        flex-direction: column;
      }
      &__list--depth2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
      }
      &__list-item {
        width: 100%;
        & + [class*='__list-item'] {
          margin-top: 2.4rem;
          padding-top: 2.4rem;
          border-top: 1px solid var(--color-white-rgba-25);
        }
      }
      .util {
        bottom: 2rem;
      }
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    h1 {
      a {
        transform: scale(0.85);
      }
    }
    .btn-menu {
      transform: scale(0.8);
    }
    .nav {
      &__list--depth2 {
        li {
          width: calc(100% / 4);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
    h1 {
      a {
        transform: scale(0.65);
      }
    }
    .btn-menu {
      transform: scale(0.6);
    }
    .nav {
      &__list--depth2 {
        li {
          width: calc(100% / 2);
        }
      }
    }
  }
`;
export default SiteHeaderView;
