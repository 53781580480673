import React from 'react';
import styled from 'styled-components';

import header from './img/ksamheung-header.png';
import textImg from './img/ksamheung-txt2.png';
import vodVideo from './vod/ksamheung-main-vod5.mp4';
import vodImg2 from './img/ksamheung-img2.jpg';
import vodImg3 from './img/ksamheung-img3.jpg';
import footerImg from './img/ksamheung-footer.jpg';
import pagenationImg from './img/ksamheung-pagenation.png';

function Video() {
  return (
    <StyledSubContainer>
      <div className="inner">
        <div className="vod-wrap">
          <img src={header} alt="" className="m-header" />
          <div className="txt-area">
            {/* <img src={textImg} alt="" className="m-txt" /> */}
            <img src={pagenationImg} alt="" className="m-pagenation" />
          </div>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="video"
            width="100%"
            height="100%"
          >
            <source src={vodVideo} type="video/mp4" />
            <track
              default
              kind="captions"
              srcLang="en"
              src="https://mis-prod-koce-esg-cdn-blob-ep.azureedge.net/web/video/c61d0d9a30e1476bb4525b9f64bbd730.vtt"
            />
          </video>
        </div>
        <img src={vodImg2} alt="" />
        <img src={vodImg3} alt="" />
        <img src={footerImg} alt="" />
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled.div`
  .inner {
    max-width: 1920px;
    margin: 0 auto;
    .vod-wrap {
      position: relative;
      font-size: 0;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
      }
      .m-header {
        position: absolute;
        top: 4rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      .txt-area {
        position: absolute;
        left: 50%;
        max-width: 1432px;
        margin: 0 auto;
        width: 100vw;
        height: 100vh;
        transform: translateX(-50%);
        z-index: 1;
        .m-txt {
          position: absolute;
          top: 35rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .m-pagenation {
        position: absolute;
        top: 38%;
        left: -22rem;
        z-index: 1;
      }
    }
  }
`;

export default Video;
