import React from 'react';

import PlaygroundView from './PlaygroundView';

/**
 * 플레이그라운드
 * @returns
 */
function Playground() {
  return <PlaygroundView />;
}

export default Playground;
