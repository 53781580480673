import React from 'react';
import PropTypes from 'prop-types';

import CustomSwiperView from './CustomSwiperView';

/**
 * Custom Swiper
 * 기본적인 Swiper 컴포넌트 생성
 * @param {*} sliders Swiper 목록
 * @param {*} setPrev 현재 페이지(외부 사용 목적)
 * @param {*} setTotal 전체 페이지(외부 사용 목적)
 * @param {import('react').Ref} 컴포넌트 ref 연결용
 * @returns CustomSwiper Component
 */
const CustomSwiper = React.forwardRef(({ sliders, setPrev, setTotal }, ref) => {
  const props = {
    sliders,
    sliderOptions: {
      slidesPerView: 2,
      spaceBetween: 18,
    },
    setPrev,
    setTotal,
  };
  return <CustomSwiperView ref={ref} {...props} />;
});

CustomSwiper.propTypes = {
  sliders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array])),
  setPrev: PropTypes.func,
  setTotal: PropTypes.func,
};

CustomSwiper.defaultProps = {
  sliders: [],
  setPrev: () => {},
  setTotal: () => {},
};

export default CustomSwiper;
