import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';

function SiteLayoutView({ children }) {
  return (
    <SiteLayout className="site-layout">
      <SiteHeader />
      <SiteContent>{children}</SiteContent>
      <SiteFooter />
    </SiteLayout>
  );
}

const SiteLayout = styled.div`
  min-height: 100vh;
`;
const SiteContent = styled.main`
  /* padding-top: 7.2rem; */
`;

export default SiteLayoutView;

SiteLayoutView.propTypes = {
  children: PropTypes.element.isRequired,
};
