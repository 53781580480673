import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import CustomSwiper from '../../molecules/CustomSwiper/CustomSwiper';

import WorkSkSustainabilityImg from '../../../assets/img/main/work-sk-sustainability-img.jpg';
import WorkCatchfunImg from '../../../assets/img/main/work-catchfun-img.jpg';
import WorkKoreantistImg from '../../../assets/img/main/work-koreantist-img.jpg';
import CatchfunImg from '../../../assets/img/ourWork/catchfun-img.jpg';

function PopupZoneView() {
  const ref = useRef();
  const [prev, setPrev] = useState(1);
  const [total, setTotal] = useState(() => {
    return 4 - 1; // 슬라이더 갯수 - 1 이 토탈
  });

  // 커스텀 스와이프에 전달하기 위한 slider 목록
  const sliders = [
    {
      href: '#',
      img: WorkSkSustainabilityImg,
      alt: 'SK hynix 지속가능성 시스템 사이트',
    },
    {
      href: '#',
      img: CatchfunImg,
      alt: 'Catch fun!',
    },
    {
      href: '#',
      img: WorkKoreantistImg,
      alt: '코리안티스트',
    },
    {
      href: '#',
      img: WorkSkSustainabilityImg,
      alt: 'SK Hynix 소통 플랫폼 구축',
    },
    {
      href: '#',
      img: WorkCatchfunImg,
      alt: 'SSK Hynix ESG 사이트 구축',
    },
  ];

  return (
    <PopupZoneWrap className="popupContainer">
      {/* <div className="wrap">
        <div className="zone">
          <header className="zone_header">
            <h2 className="zone_header-title">Popup Zone</h2>
            <Button className="close" onClick={() => {}}>
              X
            </Button>
          </header>
          <main className="zone_content">
            <CustomSwiper ref={ref} {...{ sliders, setPrev, setTotal }} />
          </main>
          <footer className="zone_footer">
            <div>
              <div className="zone_footer-first">
                <button
                  type="button"
                  onClick={() => ref.current?.slidePrev()}
                  className="swiper-button-prev"
                >
                  <span className="sr-only">이전</span>
                </button>
                <div className="swiper-pagination swiper-pagination-fraction">
                  <span className="swiper-pagination-current">
                    {`${prev}`.padStart(2, '0')}
                  </span>
                  <span>/</span>
                  <span className="swiper-pagination-total">
                    {`${total}`.padStart(2, '0')}
                  </span>
                </div>
                <button
                  type="button"
                  onClick={() => ref.current?.slideNext()}
                  className="swiper-button-next"
                >
                  <span className="sr-only">다음</span>
                </button>
              </div>
              <div className="zone_footer-second">
                <Button className="zone_close" onClick={() => {}}>
                  Cancel
                </Button>
              </div>
            </div>
          </footer>
        </div>
      </div> */}
      <div className="c-popup">
        <button type="button" className="btn-close" onClick={() => {}}>
          전체닫기 <i className="ri-close-line" />
        </button>
        <CustomSwiper ref={ref} {...{ sliders, setPrev, setTotal }} />
        {/* <footer className="c-popup__footer">
          <div>
            <div className="c-popup__footer-first">
              <button
                type="button"
                onClick={() => ref.current?.slidePrev()}
                className="swiper-button-prev"
              >
                <span className="sr-only">이전</span>
              </button>
              <div className="swiper-pagination swiper-pagination-fraction">
                <span className="swiper-pagination-current">
                  {`${prev}`.padStart(2, '0')}
                </span>
                <span>/</span>
                <span className="swiper-pagination-total">
                  {`${total}`.padStart(2, '0')}
                </span>
              </div>
              <button
                type="button"
                onClick={() => ref.current?.slideNext()}
                className="swiper-button-next"
              >
                <span className="sr-only">다음</span>
              </button>
            </div>
            <div className="c-popup__footer-second">
              <Button className="c-popup_close" onClick={() => {}}>
                Cancel
              </Button>
            </div>
          </div>
        </footer> */}
      </div>
    </PopupZoneWrap>
  );
}

const PopupZoneWrap = styled.div`
  &.popupContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: var(--color-black-rgba-85);
    .c-popup {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100rem;
      max-width: 100rem;
      .btn-close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -6rem;
        right: 0;
        padding: 0.8rem 1.6rem 0.8rem 2.2rem;
        color: var(--color-white);
        border: 1px solid var(--color-white);
        border-radius: 2rem;
        z-index: 1000;
        background: transparent;
        transition: all 0.4s;
        i {
          margin-left: 5px;
          font-size: 2rem;
        }
        &:hover {
          color: var(--color-black);
          background-color: var(--color-white);
        }
      }

      &__cont {
        padding: 2rem 0;
      }
      &__footer {
        padding: 2rem 0;
        padding-bottom: 0;

        button {
          float: right;
          padding: 0.5rem;

          border-radius: 8px;
        }
        .popup-close {
          background-color: transparent;
          font-weight: 600;

          &:hover {
            color: rgba(54, 67, 72, 0.8);
          }
        }
        .submit {
          margin-right: 1rem;
          background-color: #364348;
          color: #fff;

          &:hover {
            background-color: rgba(54, 67, 72, 0.8);
          }
        }
      }
    }
  }
  /*
    &.popupContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.35);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .wrap {
      width: 100vw;
      height: 100vh;
      background-color: #fff;
      padding: 2rem;
      color: #fff;

      background-color: rgba(0, 0, 0, 0.85);
      backdrop-filter: blur(5px);
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

      display: flex;
      justify-content: center;
      align-items: center;
      .zone {
        width: 80vw;
        height: calc(100vh);
        padding: 10rem;
        &_header {
          position: relative;
          border-bottom: 1px solid #dddddd;

          &-title {
            text-align: center;
          }

          .close {
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;

            img {
              width: 1rem;
              height: auto;
              transition: all 0.3s;
            }
            &:hover {
              img {
                transform: scale(1.1);
              }
            }
          }
        }

        &_content {
          border-bottom: 1px solid #dddddd;
          padding: 2rem 0;
        }

        &_footer {
          padding: 2rem 0;
          padding-bottom: 0;

          button {
            float: right;
            padding: 0.5rem;

            border-radius: 8px;
          }
          .popup-close {
            background-color: transparent;
            font-weight: 600;

            &:hover {
              color: rgba(54, 67, 72, 0.8);
            }
          }
          .submit {
            margin-right: 1rem;
            background-color: #364348;
            color: #fff;

            &:hover {
              background-color: rgba(54, 67, 72, 0.8);
            }
          }
        }
      }
    }
  } */
`;

export default PopupZoneView;
