/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';

function JoinAgreeView() {
  return (
    <StyledSubContainer
      isVisible
      title="Member"
      subTxt=" 홈페이지에 오신 것을 환영합니다."
      subVisual={SubVisualImg}
      className="sub-member"
    >
      <article className="c-join-agree">
        <div className="inner-container__sub">
          <h3>회원가입</h3>
          <ol className="agree-step">
            <li className="active">
              <span>Step1</span>
              <strong>약관동의</strong>
            </li>
            <li>
              <span>Step2</span>
              <strong>본인 인증</strong>
            </li>
            <li>
              <span>Step3</span>
              <strong>회원정보 입력</strong>
            </li>
            <li>
              <span>Step4</span>
              <strong>가입 완료</strong>
            </li>
          </ol>
          <h4>약관동의</h4>
          <form
            id="joinAgreeForm"
            name="joinAgreeForm"
            className="join-agree-form"
            // onSubmit={onSubmit}
          >
            <fieldset>
              <legend>약관동의</legend>
              <div className="join-agree-form__all-check">
                <span>
                  <input type="checkbox" id="saveId" name="saveId" />
                  <label htmlFor="saveId">
                    이용약관 및 개인정보 수집/이용에 모두 동의합니다.
                  </label>
                </span>
              </div>
              <div className="terms-box">
                <div className="terms-box__inner">
                  <div className="terms-box__header">
                    <h5>이용약관</h5>
                  </div>
                  <div className="terms-box__body">
                    <div className="terms-scroll scrollBar-custom" tabIndex="0">
                      제 1 장 총칙
                      <br /> 제 1 조 (목적)
                      <br /> 본 서비스 약관은 제공하는 서비스를 이용함에 있어
                      회사와 회사의 서비스를 이용하는 자 간의 권리·의무 및
                      책임사항을 규정하는데 목적이 있습니다.
                      <br /> 제 2 조 (약관의 효력 및 변경)
                      <br /> ① 본 약관은 서비스를 이용하고자 하는 모든 회원에
                      대하여 그 효력을 발생합니다. 단, 개별 서비스에 관련된
                      이용약관이 있을 경우 관련 서비스 이용약관과 함께 효력을
                      발생합니다.
                      <br /> ② 본 약관의 내용은 서비스 화면에 게시하거나 기타의
                      방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에
                      가입함으로써 효력이 발생합니다.
                      <br /> 제 1 조 (목적)
                      <br /> 본 서비스 약관은 제공하는 서비스를 이용함에 있어
                      회사와 회사의 서비스를 이용하는 자 간의 권리·의무 및
                      책임사항을 규정하는데 목적이 있습니다.
                      <br /> 제 2 조 (약관의 효력 및 변경)
                      <br /> ① 본 약관은 서비스를 이용하고자 하는 모든 회원에
                      대하여 그 효력을 발생합니다. 단, 개별 서비스에 관련된
                      이용약관이 있을 경우 관련 서비스 이용약관과 함께 효력을
                      발생합니다.
                      <br /> ② 본 약관의 내용은 서비스 화면에 게시하거나 기타의
                      방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에
                      가입함으로써 효력이 발생합니다.
                      <br /> 제 1 조 (목적)
                      <br /> 본 서비스 약관은 제공하는 서비스를 이용함에 있어
                      회사와 회사의 서비스를 이용하는 자 간의 권리·의무 및
                      책임사항을 규정하는데 목적이 있습니다.
                      <br /> 제 2 조 (약관의 효력 및 변경)
                      <br /> ① 본 약관은 서비스를 이용하고자 하는 모든 회원에
                      대하여 그 효력을 발생합니다. 단, 개별 서비스에 관련된
                      이용약관이 있을 경우 관련 서비스 이용약관과 함께 효력을
                      발생합니다.
                      <br /> ② 본 약관의 내용은 서비스 화면에 게시하거나 기타의
                      방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에
                      가입함으로써 효력이 발생합니다.
                    </div>
                  </div>
                </div>
                <div className="terms-check">
                  <input type="checkbox" id="checkAgree1" name="checkAgree" />
                  <label htmlFor="checkAgree1">이용약관에 동의합니다.</label>
                </div>
              </div>
              <div className="terms-box">
                <div className="terms-box__inner">
                  <div className="terms-box__header">
                    <h5>개인정보 수집/이용 동의</h5>
                  </div>
                  <div className="terms-box__body">
                    <div className="terms-scroll scrollBar-custom" tabIndex="0">
                      모든 개인정보는 『개인정보 보호법』 등 관련 법령상의
                      개인정보보호 규정을 준수하여 수집·보유·처리되고 있으며,
                      『개인정보 보호법』 제30조에 따라 정보주체의 개인정보를
                      보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
                      있도록 하기 위하여 다음과 같이 개인정보 처리지침을
                      수립·공개합니다.
                    </div>
                  </div>
                </div>
                <div className="terms-check">
                  <input type="checkbox" id="checkAgree2" name="checkAgree" />
                  <label htmlFor="checkAgree2">
                    개인정보 수집 및 이용에 동의합니다.
                  </label>
                </div>
              </div>
              <div className="join-agree-form__btn">
                <Button type="submit" size="md">
                  동의안함
                </Button>
                <Button type="submit" size="md" variant="default">
                  동의
                </Button>
              </div>
            </fieldset>
          </form>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  .agree-step {
    /* display: table;
    table-layout: fixed; */
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 4rem;
    > li {
      position: relative;
      counter-increment: stepNum;
      flex: 1;
      /* display: table-cell; */
      text-align: center;
      color: #ff6347;
      &::before {
        content: '✓';
        display: block;
        margin: 0 auto 4px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-weight: bold;
        border: 2px solid #ff6347;
        border-radius: 50%;
        background-color: #fff;
      }
      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #ff6347;
        position: absolute;
        top: 20px;
        left: 50%;
        z-index: -1;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.active {
        &:before {
          content: counter(stepNum);
          font-family: inherit;
          font-weight: 700;
          color: #fff;
          background-color: #ff6347;
          border-color: #ff6347;
        }
        &:after {
          background-color: #ededed;
        }
        ~ li {
          color: #808080;
          &:before {
            content: counter(stepNum);
            font-family: inherit;
            font-weight: 700;
            background-color: #ededed;
            border-color: #ededed;
          }
          &:after {
            background-color: #ededed;
          }
        }
      }
      span {
        text-transform: uppercase;
      }
      strong {
        display: block;
        font-weight: 500;
      }
    }
  }
  .join-agree-form {
    &__all-check {
      margin-bottom: 1.6rem;
      font-size: 1.7rem;
    }
    &__btn {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 2.4rem;
    }
  }
  .terms-box {
    & + .terms-box {
      margin-top: 2.4rem;
    }
    &__inner {
      border: 1px solid var(--color-gray-d9d9d9);
    }
    &__header {
      padding: 1.2rem 2rem;
      border-bottom: 1px solid var(--color-gray-d9d9d9);
      background: var(--color-gray-f7f7f7);
      h5 {
        font-size: 1.7rem;
        margin-bottom: 0;
      }
    }
    &__body {
      padding: 3.2rem;
    }
    .terms-scroll {
      max-height: 25rem;
      overflow-y: auto;
    }
    .terms-check {
      padding: 1rem 0;
    }
  }
`;

export default JoinAgreeView;
