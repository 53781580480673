import React from 'react';
import { message } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from '../../../hooks/useQuery';

import ContactUsView from './ContactUsView';
import { SITE_ID } from '../../../config/constants';
/**
 * 플레이그라운드
 * @returns
 */
function ContactUs() {
  /**
   * API - 정보저장
   */
  const saveMutation = useMutation('/api/ctt/insertCtt');

  // formik
  const formik = useFormik({
    initialValues: {
      company: '',
      questions: '',
    },
    validate: values => {
      const errors = {};
      const { company, questions } = values;
      if (!company) {
        errors.company = '회사명을 입력해 주세요.';
      }
      if (!questions) {
        errors.questions = '문의사항을 입력해 주세요.';
      }

      return errors;
    },
    onSubmit: values => {
      const sendValues = {
        ...values,
        words: values.questions,
        siteId: SITE_ID,
        frstRegisterId: 'guest',
      };
      // API - 문의사항 저장
      saveMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            message.success('문의사항이 저장 되었습니다.');
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ onError : error', JSON.stringify(error));
        },
        onSettled: async () => {
          formik.resetForm();
        },
      });
    },
  });

  const props = {
    formik,
  };

  return <ContactUsView {...props} />;
}

export default ContactUs;
