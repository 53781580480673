import React from 'react';

import PageInfoView from './PageInfoView';

function PageInfo(props) {
  return (
    <div className="c-bbs__total">
      <PageInfoView {...props} />
    </div>
  );
}

export default PageInfo;
