import React from 'react';
import styled from 'styled-components';

import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/support/sub-visual.jpg';
import ProcessIco1 from '../../../assets/img/support/ico-process1.png';
import ProcessIco2 from '../../../assets/img/support/ico-process2.png';
import ProcessIco3 from '../../../assets/img/support/ico-process3.png';
import ProcessIco4 from '../../../assets/img/support/ico-process4.png';
import ArrowIco from '../../../assets/img/support/ico-arrow.png';

function EmploymentView() {
  return (
    <StyledSubContainer
      isVisible
      title="Support"
      subTxt="바능의 다양한 소식을 보실 수 있습니다."
      subVisual={SubVisualImg}
      className="sub-support"
    >
      <h3>채용안내</h3>
      <article className="c-process">
        <div className="inner-container__sub">
          <h4>채용프로세스</h4>
          <ul className="c-process__list">
            <li className="list-item--type1">
              <span>STEP 01</span>
              <strong>서류전형</strong>
            </li>
            <li className="list-item--type2">
              <span>STEP 02</span>
              <strong>
                1차 <br />
                직무면접
              </strong>
            </li>
            <li className="list-item--type3">
              <span>STEP 03</span>
              <strong>
                인성검사 <br />
                최종면접
              </strong>
            </li>
            <li className="list-item--type4">
              <span>STEP 04</span>
              <strong>최종합격</strong>
            </li>
          </ul>
        </div>
      </article>
      <article className="c-document">
        <div className="inner-container__sub">
          <h4>제출서류</h4>
          <div className="info-box">
            채용사이트 양식의 입사지원서(이력서, 자기소개서) 제출된 서류는 반환
            되지 않습니다.
          </div>
        </div>
      </article>
      <article className="c-document">
        <div className="inner-container__sub">
          <h4>제출방법 및 문의</h4>
          <div className="info-box">
            <ul className="c-document__list">
              <li>
                <strong>
                  <i className="ri-mail-send-line" /> 이메일
                </strong>
                <p>admin@reacting.kr (문의사항은 메일로 해주세요.)</p>
              </li>
              <li>
                <strong>
                  <i className="ri-building-line" /> 우 편
                </strong>
                <p>
                  경기도 성남시 성남대로 295 대림아크로빌 C동 218호 (주식회사
                  바능)
                </p>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3 {
    padding-top: 8rem;
    text-align: center;
  }
  .c-process {
    text-align: center;
    background: var(--color-gray-F6F8FC);
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 4rem;
      li {
        position: relative;
        /* border-right: 1px solid var(--color-main); */
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          bottom: 0;
          width: 1px;
          background: var(--color-main);
        }
        &::after {
          content: '';
          position: absolute;
          top: 75%;
          transform: translateY(-50%);
          right: -3rem;
          width: 4rem;
          height: 4rem;
          border: 1rem solid var(--color-gray-F6F8FC);
          border-radius: 50%;
          background: url(${ArrowIco}) no-repeat center / 4rem;
        }
        &:last-child {
          &::before,
          &::after {
            display: none;
          }
        }
        span {
          display: inline-block;
          margin: 2.5rem 0 1.5rem;
          color: var(--color-main);
          font-size: 2.1rem;
          font-weight: 700;
        }
        strong {
          display: block;
          font-size: 3rem;
          line-height: 1.25;
        }
        &[class^='list-item'] {
          span {
            &::before {
              content: '';
              display: block;
              width: 8rem;
              height: 8rem;
              margin: 0px auto 3rem;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 8rem;
            }
          }
          &[class*='--type1'] {
            span {
              &::before {
                background-image: url(${ProcessIco1});
              }
            }
          }
          &[class*='--type2'] {
            span {
              &::before {
                background-image: url(${ProcessIco2});
              }
            }
          }
          &[class*='--type3'] {
            span {
              &::before {
                background-image: url(${ProcessIco3});
              }
            }
          }
          &[class*='--type4'] {
            span {
              &::before {
                background-image: url(${ProcessIco4});
              }
            }
          }
        }
      }
    }
  }
  .c-document {
    h4 {
      text-align: center;
    }
    &__list {
      li {
        display: flex;
        flex-direction: column;
        & + li {
          margin-top: 1rem;
        }
      }
    }
  }

  @media ${({ theme }) => theme.media.lgMin} {
    .c-process {
      &__list {
        li {
          width: calc(100% / 4);
        }
      }
    }
    .c-document {
      &__list {
        li {
          flex-direction: row;
          strong {
            flex: 0 0 10rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    .c-process {
      &__list {
        li {
          width: calc(100% / 2);
          &::before {
            transform: translateY(-50%);
          }
          &::after {
            top: 50%;
          }
          &[class^='list-item'] {
            &[class*='--type1'] {
              order: 1;
            }
            &[class*='--type2'] {
              order: 2;
              &::before {
                top: auto;
                left: 50%;
                bottom: -5.5rem;
                width: 38%;
                height: 1px;
                transform: translateX(calc(-50% + 0.5px));
              }
              &::after {
                left: 50%;
                right: auto;
                top: auto;
                bottom: -8.5rem;
                transform: translateX(calc(-50% + 0.5px)) rotate(90deg);
              }
            }
            &[class*='--type3'] {
              order: 4;
              margin-top: 8rem;
              &::before {
                right: auto;
                left: 0;
              }
              &::after {
                top: 38%;
                left: -3rem;
                right: auto;
                transform: scaleX(-1);
              }
            }
            &[class*='--type4'] {
              order: 3;
              margin-top: 8rem;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
    .c-process {
      &__list {
        li {
          width: calc(100% / 2 + 1px);
          margin-top: -1px;
          margin-left: -1px;
          padding-bottom: 2.5rem;
          border: 1px solid var(--color-gray-d9d9d9);
          background: var(--color-white);
          &::after,
          &::before {
            display: none;
          }
          strong {
            font-size: 2.5rem;
            br {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default EmploymentView;
