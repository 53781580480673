import React from 'react';

import AboutUsView from './AboutUsView';

/**
 * 회사소개
 * @returns
 */
function AboutUs() {
  return <AboutUsView />;
}

export default AboutUs;
