import React from 'react';

import SurveyView from './SurveyView';

/**
 * 설문조사
 * @returns
 */
function Survey() {
  return <SurveyView />;
}

export default Survey;
