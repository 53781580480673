import React from 'react';

import LocationView from './LocationView';

/**
 * 플레이그라운드
 * @returns
 */
function Location() {
  return <LocationView />;
}

export default Location;
