import React from 'react';
import PropTypes from 'prop-types';
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from 'react-query';

/**
 * UseQuery를 사용하기 위한 Provier
 * 페이지의 템플릿을 호출하는 상위에서 적용하는 것을 추천.
 * @param {*} children 본문 템플릿
 */
function QueryClientProvider({ children }) {
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
}

/**
 * QueryClient 환경 설정 옵션 구성
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // 창에 포커스가 되었을 때 Refetch 옵션
      refetchOnMount: false, // 컴포넌트가 마운트 되었을 때 Refetch 옵션
      refetchOnReconnect: false, // 페이지가 재 연결 되었을 때 Refetch 옵션
      retry: false, // 실패한 쿼리에 대한 자동 재시도 설정
      staleTime: 1000 * 60 * 60 * 24, // 데이터가 Stale 상태로 바뀌는데 걸리는 시간 옵션 설정
    },
  },
});

export default QueryClientProvider;

QueryClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
