import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HTMLRenderer from 'react-html-renderer';

function TestSampleView({ data }) {
  return (
    <StyledContentContainer id="content" className="sub-about">
      <article className="c-contents">
        {/* <HTMLRenderer html={data?.content} /> */}

        {/* 회사연혁 visual */}
        <div className="sub-visual">
          <div className="sub-visual__title">
            <h2>About</h2>
            <p>
              바능은 창의적인 인재가 모여있는, 항상 새로운 기술에 도전하고,
              현재보다 나은 미래를 위해 바능 구성원들과 함께 만들어가고
              있습니다.
            </p>
          </div>
          <div className="scroll-down">
            <p>SCROLL</p>
            <div className="scroll-down__bg">
              <div className="scroll-down__bar" />
            </div>
          </div>
        </div>
        <article className="c-org">
          <div className="inner-container__sub">
            <h3>조직도</h3>
            <div className="c-org__cont">
              <ul className="c-org__list">
                <li>
                  <div className="list--depth">
                    <strong>대표이사</strong>
                    <span>이사회</span>
                  </div>
                  <ul className="list--depth1">
                    <li>
                      <strong>SI 사업부</strong>
                      <ul className="list--depth2">
                        <li>
                          <span>SI 1팀</span>
                        </li>
                        <li>
                          <span>SI 2팀</span>
                        </li>
                        <li>
                          <span>SI 3팀</span>
                        </li>
                        <li>
                          <span>디자인팀</span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>서비스 사업부</strong>
                      <ul className="list--depth2">
                        <li>
                          <span>SM</span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>R&amp;D 사업부</strong>
                      <ul className="list--depth2">
                        <li>
                          <span>기업부설연구소</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </article>
        {/*  */}
      </article>
    </StyledContentContainer>
  );
}

const StyledContentContainer = styled.section`
  /* 서브 컨텐츠 style */
  .sub-inner {
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      background: #333;
    }
    [class*='__title'] {
      max-width: var(--cantainer-width);
      width: 100%;
      margin: 0 auto;
      padding: 8rem 1.6rem 0;
      h2 {
        color: var(--color-black);
        font-size: 6.8rem;
        line-height: 1;
        text-transform: uppercase;
      }
    }
    & + .inner-container {
      [class*='__sub'] {
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
  .sub-visual {
    display: flex;
    align-items: center;
    position: relative;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background-image: url('https://reacting.kr/static/media/sub-visual.53ebb1c959b5a577ff57.jpg'); */
    [class*='__title'] {
      max-width: var(--cantainer-width);
      width: 100%;
      margin: 0 auto;
      padding: 0 1.6rem;
      h2 {
        color: var(--color-white);
        font-family: var(--font-GmarketSans);
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 3rem;
        animation: txt-blur 1.5s 0.1s both;
        @-webkit-keyframes txt-blur {
          0% {
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            filter: blur(0);
            opacity: 1;
          }
        }
        @keyframes txt-blur {
          0% {
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            filter: blur(0);
            opacity: 1;
          }
        }
      }
      p {
        color: var(--color-white);
        font-size: 2rem;
        line-height: 1.8;
        opacity: 0;
        animation: txt-Up 0.7s 0.5s both;
        @-webkit-keyframes txt-Up {
          0% {
            visibility: visible;
            opacity: 0;
            transform: translate3d(0, 50%, 0);
            filter: blur(8px);
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            filter: blur(0);
          }
        }
        @keyframes txt-Up {
          0% {
            visibility: visible;
            opacity: 0;
            transform: translate3d(0, 50%, 0);
            filter: blur(8px);
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            filter: blur(0);
          }
        }
      }
    }
    .scroll-down {
      position: absolute;
      left: 50%;
      bottom: 2rem;
      transform: translateX(-50%);
      color: var(--color-white);
      p {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        font-weight: 700;
      }
      &__bg {
        position: relative;
        left: 50%;
        width: 1px;
        height: 5rem;
        background-color: var(--color-white-rgba-25);
      }
      &__bar {
        position: relative;
        /* left: 50%; */
        left: calc(50% + 0.5px);
        transform: translateX(-50%);
        width: 1px;
        height: 2rem;
        background-color: var(--color-white);
        animation: move-down 1.2s both linear infinite;
        @keyframes move-down {
          0% {
            opacity: 0;
            transform: translateY(0) translateX(-1px);
          }
          35% {
            opacity: 1;
            transform: translateY(0.75rem) translateX(-1px);
          }
          70% {
            opacity: 1;
            transform: translateY(2rem) translateX(-1px);
          }
          100% {
            opacity: 0;
            transform: translateY(3rem) translateX(-1px);
          }
        }
      }
    }
  }
  .inner-container {
    &__sub {
      max-width: var(--cantainer-width);
      margin: 0 auto;
      padding: 8rem 1.6rem;
      h3 {
        position: relative;
        margin: 0 auto 8rem;
        text-transform: uppercase;
      }
      h4 {
        font-size: 4.5rem;
        margin: 0 auto 5rem;
      }
      .info-txt {
        font-size: 2rem;
        font-weight: 500;
      }
      .info-box {
        font-size: 2rem;
        background: var(--color-gray-F6F8FC);
      }
    }
  }

  @media ${({ theme }) => theme.media.lgMin} {
    .sub-inner {
      &::before {
        height: 9.5rem;
      }
      [class*='__title'] {
        text-align: center;
        p {
          margin-top: 2rem;
        }
      }
    }
    .sub-visual {
      [class*='__title'] {
        h2 {
          font-size: 10rem;
        }
        p {
          max-width: 60rem;
        }
      }
    }
    .inner-container {
      &__sub {
        h3 {
          font-size: 6.8rem;
        }
        .info-box {
          padding: 4.5rem 10rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    .sub-inner {
      &::before {
        height: 6.8rem;
      }
    }
    .sub-visual {
      &__title {
        h2 {
          font-size: 8rem;
        }
      }
    }
    .inner-container {
      &__sub {
        h3 {
          font-size: 5.5rem;
        }
        .info-box {
          padding: 2.5rem 4rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    &:not(.sub-support) {
      .sub-visual {
        height: 48rem;
      }
    }
    .sub-visual {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--color-black-rgba-25);
      }
      &__title {
        position: relative;
        z-index: 1;
        margin-top: 6rem;
        text-align: center;
        p {
          padding: 0 4rem;
        }
      }
      .scroll-down {
        display: none;
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
    .sub-inner {
      &::before {
        height: 7.5rem;
      }
    }
    .sub-visual {
      &__title {
        h2 {
          font-size: 7rem;
        }
      }
    }
    .inner-container {
      &__sub {
        h3 {
          font-size: 5.2rem;
        }
        .info-box {
          padding: 2rem 2.5rem;
        }
      }
    }
  }

  .sub-inner {
    [className*='__title'] {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      border: 0;
      clip: rect(0, 0, 0, 0);
    }
  }

  /* 조직도 */
  .sub-visual {
    height: 50rem !important;
    background-image: url('/static/media/sub-visual.53ebb1c959b5a577ff57.jpg');
  }
  .sub-visual .scroll-down {
    display: none;
  }
  .c-org h3 {
    margin-bottom: 5.5rem;
    text-align: center;
  }
  .c-org__cont {
    position: relative;
    text-align: center;
  }
  .c-org__list > li > .list--depth {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
  }
  .c-org__list > li > .list--depth::after {
    content: '';
    position: absolute;
    width: 1px;
    display: inline-block;
    background: var(--color-gray-c8c8c8);
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .c-org__list > li > .list--depth > strong {
    display: inline-block;
    padding: 2rem 10rem;
    color: var(--color-white);
    font-size: 2rem;
    background: var(--color-main);
  }
  .c-org__list > li > .list--depth > span {
    display: inline-block;
    margin-top: 6rem;
    margin-right: -55%;
    color: var(--color-main);
    font-size: 2rem;
    border: 2px solid var(--color-main);
    background: var(--color-white);
  }
  .c-org__list > li > .list--depth > span::after {
    content: '';
    position: absolute;
    left: 50%;
    width: 28%;
    height: 1px;
    display: inline-block;
    background: var(--color-gray-c8c8c8);
    z-index: -1;
  }
  .c-org__list > li > .list--depth1 > li {
    flex: 1;
    background: var(--color-gray-f7f7f7);
  }
  .c-org__list > li > .list--depth1 > li > strong {
    display: block;
    padding: 2rem 0;
    color: var(--color-white);
    font-size: 2rem;
    font-weight: 300;
    background: var(--color-gray-969696);
  }
  .c-org__list > li > .list--depth1 > li .list--depth2 {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 3rem;
  }
  .c-org__list > li > .list--depth1 > li .list--depth2 li {
    flex: 1;
    padding: 2rem 0;
    font-size: 1.7rem;
    border: 2px solid var(--color-gray-d9d9d9);
    background: var(--color-white);
  }
  .c-org__list > li > .list--depth1 > li:first-child .list--depth2 li {
    flex: 0 0 calc(50% - 1rem);
  }
  @media all and (min-width: 993px) {
    .c-org__list > li > .list--depth1 {
      gap: 4rem;
    }
  }
  @media all and (max-width: 992px) {
    .c-org__list > li > .list--depth1 {
      gap: 2rem;
    }
  }
  @media screen and (min-width: 769px) {
    .c-org__list > li > .list--depth {
      padding-bottom: 10rem;
    }
    .c-org__list > li > .list--depth > span {
      padding: 1.8rem 10rem;
    }
    .c-org__list > li > .list--depth > span::after {
      top: 55%;
    }
    .c-org__list > li > .list--depth1 {
      display: flex;
    }
  }
  @media all and (max-width: 768px) {
    .c-org__list > li > .list--depth {
      padding-bottom: 6rem;
    }
    .c-org__list > li > .list--depth1 > li + li {
      margin-top: 4rem;
    }
    .c-org__list > li > .list--depth > span {
      padding: 1.8rem 5rem;
    }
    .c-org__list > li > .list--depth > span::after {
      top: 63%;
    }
  }
`;

TestSampleView.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
};

TestSampleView.defaultProps = {
  data: {},
};

export default TestSampleView;
