import React from 'react';

import CatchfunView from './CatchfunView';

/**
 * 플레이그라운드
 * @returns
 */
function Catchfun() {
  return <CatchfunView />;
}

export default Catchfun;
