import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ChatBoxView from './ChatBoxView';

/**
 * 채팅 박스 컴포넌트
 * 채팅 메시지를 주고 받을 때 사용하는 모듈
 * @param {string} roomId 채팅 방 고유 번호
 * @param {string} wsSourceUrl 백엔드 웹 소켓 Target Url
 * @param {string} randomUserId 랜덤 유저 아이디
 * @param {string} randomUserName 랜덤 유저 이름
 */
function ChatBox({ roomId, wsSourceUrl, randomUserId, randomUserName }) {
  /**
   * SockJsClient의 Ref를 연결하기 위한 Ref
   * Message Receive를 위한 정보
   */
  const ref = React.createRef();

  /**
   * 채팅 템플릿 Getter/Setter
   */
  const [chatTemplate, setChatTemplate] = useState({
    clientConnected: false,
    messages: [],
  });

  /**
   * Message Receive 핸들러
   * 클라이언트 Receive from Chatting Server
   * @param {*} msg 메시지 Obejct
   * @param {*} topic 예약된 토픽 이름
   */
  const handleMessageReceive = (msg, topic) => {
    setChatTemplate(prevState => ({
      ...prevState,
      messages: [...(prevState?.messages ?? ''), msg],
    }));
  };

  /**
   * 메시지 Sender 핸들러
   * 클라이언트 to Chatting Server
   * @param {*} msg 메시지 Object
   * @param {*} selfMsg 송신자가 작성한 메시지 Object
   * @returns
   */
  const handleMessageSend = (msg, selfMsg) => {
    try {
      const sendMessage = {
        roomId,
        user: selfMsg.author,
        message: selfMsg.message,
      };
      ref.current.sendMessage('/app/message', JSON.stringify(sendMessage));
      return true;
    } catch (e) {
      return false;
    }
  };

  /**
   * Chat Box View에 전달하기 위한 Props
   */
  const props = {
    wsSourceUrl, // 웹 소켓 Target Url
    roomId, // 채팅 룸 고유 번호
    randomUserId, // 유저 아이디
    randomUserName, // 유저 이름
    chatTemplate, // 채팅 템플릿 Object Getter
    setChatTemplate, // 채팅 템플릿 Object Setter
    onMessageReceive: handleMessageReceive, // 메시지 Receive 핸들러
    onMessageSend: handleMessageSend, // 메시지 Sender 핸들러
  };

  return <ChatBoxView ref={ref} {...props} />;
}

ChatBox.propTypes = {
  roomId: PropTypes.string,
  wsSourceUrl: PropTypes.string,
  randomUserId: PropTypes.string,
  randomUserName: PropTypes.string,
};

ChatBox.defaultProps = {
  wsSourceUrl: '',
  roomId: '',
  randomUserId: '',
  randomUserName: '',
};

export default ChatBox;
