import React from 'react';

const JoinCompleteIcon = props => {
  return (
    <svg viewBox="0 0 400 300" width="406" height="306">
      <ellipse
        cx="192.35"
        cy="255.21"
        rx="133.42"
        ry="16.69"
        fill="#e6e6e6"
        opacity="0.45"
        {...props}
      />
      <path
        d="M202.82,118.71a60.86,60.86,0,0,1,19.84,37.48c3.19,24.29,34.07,6,42.94-20.59l8.52,5.68s-13.1,45.33-51.46,47.2S170.56,128.79,202.82,118.71Z"
        fill="#2abfb8"
      />
      <path
        d="M202.82,118.71a60.86,60.86,0,0,1,19.84,37.48c3.19,24.29,34.07,6,42.94-20.59l8.52,5.68s-13.1,45.33-51.46,47.2S170.56,128.79,202.82,118.71Z"
        fill="#fff"
        opacity="0.46"
      />
      <path
        d="M126.1,76.86c-20,9.62-34.21,32.63-36.14,59.86-1.63,23,6.6,56.08,42.12,61.93C201.2,210,273.55,215.73,288.49,177.33S286.66,62.1,251.7,59.25C223.9,57,165.14,58.06,126.1,76.86Z"
        fill="#e6e6e6"
        opacity="0.3"
      />
      <path
        d="M189.86,84.87A34.07,34.07,0,0,1,193,93.34a2.73,2.73,0,0,1-2.29,3.13,6,6,0,0,1-6.14-2.31l-3.37-3.73a4.89,4.89,0,0,1-.08-5.65C183.09,81.54,188.44,81.86,189.86,84.87Z"
        fill="#f4a28c"
      />
      <polygon
        points="183.73 91.13 179.45 108.46 189.17 110.13 188.4 97.18 183.73 91.13"
        fill="#f4a28c"
      />
      <path
        d="M191.32,88l2.23,1.33a.88.88,0,0,1,0,1.48L191.83,92Z"
        fill="#f4a28c"
      />
      <path
        d="M188.45,96.5s-3.57-.77-4.75-1.89c0,0,.33,8.12,5.09,9.18Z"
        fill="#ce8172"
        opacity="0.31"
      />
      <path
        d="M182.13,89.49s-2.92-.94-3.42,1.33,2.75,3.23,4.42,1.54A4.39,4.39,0,0,1,182.13,89.49Z"
        fill="#f4a28c"
      />
      <rect
        x="142.34"
        y="205.8"
        width="63.12"
        height="45.11"
        transform="translate(347.8 456.7) rotate(-180)"
        fill="#e6e6e6"
      />
      <polygon
        points="188.8 250.91 191.83 211.19 204.5 213.12 205.4 225.17 205.46 250.91 188.8 250.91"
        opacity="0.08"
      />
      <path
        d="M190.38,245.69s1.36,3.76,4.75,4.05,4.29,3.61,1.24,4.47-10.94-3.06-10.94-3.06l.22-4.9Z"
        fill="#2abfb8"
      />
      <path
        d="M233.31,246.09s3.73,1.41,6.16-1,5.53-.92,4.2,2-9.42,6.33-9.42,6.33l-4.42-4.13Z"
        fill="#2abfb8"
      />
      <path
        d="M190,109.94l-9.72-1.68s-32.33,0-38,11.12-4.47,52.28,14.23,86.11l38.29,1.1s-2.13-24.84,5-38.37S229.13,125.94,190,109.94Z"
        fill="#2abfb8"
      />
      <path
        d="M156.52,205.49l18.73-2.83a17.92,17.92,0,0,1,20.5,19.56L193.31,246h-9.19l.79-22.27a8.43,8.43,0,0,0-9.76-8.64C167.18,216.39,157.31,215.83,156.52,205.49Z"
        fill="#24285b"
      />
      <path
        d="M159.8,123.35s10.62,9.3,8.49,19.58c-.35,1.66-.71,3.66-1.07,5.86a58.63,58.63,0,0,0,13.77,48l6.68,7.65-31.15,1.05-2.67-5.22L139.31,176.1Z"
        opacity="0.08"
      />
      <path
        d="M141.75,120.4c-7.09,10-13.4,32-7.92,63.08A20.83,20.83,0,0,0,153,200.61l29.26,1.85v-9.55l-22.23-3.21a8.52,8.52,0,0,1-6.94-10.92c3.92-12.9,10-36.26,8.42-52.91C160.59,115.76,147.63,112.12,141.75,120.4Z"
        fill="#2abfb8"
      />
      <path
        d="M141.75,120.4c-7.09,10-13.4,32-7.92,63.08A20.83,20.83,0,0,0,153,200.61l29.26,1.85v-9.55l-22.23-3.21a8.52,8.52,0,0,1-6.94-10.92c3.92-12.9,10-36.26,8.42-52.91C160.59,115.76,147.63,112.12,141.75,120.4Z"
        fill="#fff"
        opacity="0.46"
      />
      <path
        d="M182.3,195.23s11.87-.3,10.18,5.89-10.18,1.34-10.18,1.34Z"
        fill="#f4a28c"
      />
      <polygon
        points="221.8 204.68 226.59 177.59 192.6 177.59 189.65 200.69 174.07 200.9 174.07 204.68 221.8 204.68"
        fill="#ffd200"
      />
      <circle cx="208.26" cy="190.58" r="3.4" fill="#fff" />
      <path
        d="M112.48,240.25s-9.31-2.54-11.33-11.21c0,0,14.42-2.91,14.83,12Z"
        fill="#2abfb8"
        opacity="0.58"
      />
      <path
        d="M113.62,239.32s-6.5-10.28-.78-19.89c0,0,11,7,6.09,19.92Z"
        fill="#2abfb8"
        opacity="0.73"
      />
      <path
        d="M115.3,239.33s3.43-10.86,13.82-12.91c0,0,1.95,7.05-6.73,12.94Z"
        fill="#2abfb8"
      />
      <polygon
        points="108.55 239.09 110.43 252 122.32 252.05 124.07 239.15 108.55 239.09"
        fill="#24285b"
      />
      <path
        d="M182.82,96.57s-9.24-2.45-6.32-8.6c0,0-3.27-5.21,2.16-7.87,3.2-1.57,7.94-2.4,11.3-2.82a2.45,2.45,0,0,1,2.39,3.7,12,12,0,0,1-8.65,5.75A11.33,11.33,0,0,1,182.82,96.57Z"
        fill="#24285b"
      />
      <path
        d="M185.11,90.62s-.86-1.91-2.25-1.14-.15,3.54,1.93,3Z"
        fill="#f4a28c"
      />
      <path
        d="M267,136.5s3.62-12.43,6.28-12.61,0,7.1,0,7.1,9.76,2.48.89,10.29Z"
        fill="#f4a28c"
      />
      <circle cx="278.37" cy="57.16" r="27.89" fill="#ffd200" />
      <circle cx="284.65" cy="53.01" r="14.25" fill="#fff" opacity="0.46" />
      <circle cx="289.44" cy="50.93" r="7.79" fill="#fff" opacity="0.46" />
      <rect x="262.11" y="79.69" width="32.52" height="25.38" fill="#24285b" />
      <rect x="270.4" y="105.07" width="15.59" height="10.18" fill="#24285b" />
      <rect
        x="270.4"
        y="105.07"
        width="15.59"
        height="10.18"
        fill="#fff"
        opacity="0.75"
      />
      <path
        d="M238.61,38.76S147.37,9.2,124.07,49.86C108.41,77.17,140,92.53,157,90"
        fill="none"
        stroke="#e6e6e6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        strokeDasharray="2 10"
      />
      <path
        d="M205,204.26l29.82,40.37L229,250s-25.94-30.29-39.86-38.82l-4.86-6.51Z"
        fill="#24285b"
      />
    </svg>
  );
};

export default JoinCompleteIcon;
