import React from 'react';

import OurWorkView from './OurWorkView';

/**
 * Our Work
 * @returns
 */
function OurWork() {
  return <OurWorkView />;
}

export default OurWork;
