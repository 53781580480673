import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * 서브페이지 레이아웃
 * @returns
 */

// eslint-disable-next-line react/prop-types
function SubContainerVeiw({
  isVisible,
  children,
  title,
  subTxt,
  subVisual,
  ...rest
}) {
  return (
    <SubContainer {...rest}>
      {isVisible ? (
        <>
          <div
            className="sub-visual"
            style={{ backgroundImage: `url(${subVisual}` }}
          >
            <div className="sub-visual__title">
              <h2>{title}</h2>
              {subTxt && <p>{subTxt}</p>}
            </div>
            <div className="scroll-down">
              <p>SCROLL</p>
              <div className="scroll-down__bg">
                <div className="scroll-down__bar" />
              </div>
            </div>
          </div>
          <div id="content" className="inner-container">
            {children}
          </div>
        </>
      ) : (
        <>
          <div className="sub-inner">
            <div className="sub-visual__title">
              <h2>{title}</h2>
              {subTxt && <p>{subTxt}</p>}
            </div>
            {/* <div className="scroll-down">
              <p>SCROLL</p>
              <div className="scroll-down__bg">
                <div className="scroll-down__bar" />
              </div>
            </div> */}
          </div>
          <div id="content" className="inner-container">
            {children}
          </div>
        </>
      )}
    </SubContainer>
  );
}

const SubContainer = styled.section`
  &.sub-support,
  &.sub-catchfun,
  &.sub-about {
    .sub-visual {
      height: 50rem;
    }
    .scroll-down {
      display: none;
    }
  }
  &.sub-login,
  &.sub-member {
    .sub-visual {
      height: 50rem;
    }
    .scroll-down {
      display: none;
    }
    .inner-container {
      h4 {
        padding-bottom: 3rem;
        margin-bottom: 3.2rem;
        font-size: 4rem;
        text-align: center;
        border-bottom: 1px dashed var(--color-gray-eaeaea);
      }
    }
  }
  &:not(.sub-about):not(.sub-support):not(.sub-login):not(.sub-member):not(
      .sub-catchfun
    ) {
    .sub-visual {
      height: 100vh;
    }
  }
  .sub-inner {
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      background: #333;
    }
    [class*='__title'] {
      max-width: var(--cantainer-width);
      width: 100%;
      margin: 0 auto;
      padding: 8rem 1.6rem 0;
      h2 {
        color: var(--color-black);
        font-size: 6.8rem;
        line-height: 1;
        text-transform: uppercase;
      }
    }
    & + .inner-container {
      [class*='__sub'] {
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
  .sub-visual {
    display: flex;
    align-items: center;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background: fixed; */
    [class*='__title'] {
      max-width: var(--cantainer-width);
      width: 100%;
      margin: 0 auto;
      padding: 0 1.6rem;
      h2 {
        color: var(--color-white);
        font-family: var(--font-GmarketSans);
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 3rem;
        animation: txt-blur 1.5s 0.1s both;
        @-webkit-keyframes txt-blur {
          0% {
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            filter: blur(0);
            opacity: 1;
          }
        }
        @keyframes txt-blur {
          0% {
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            filter: blur(0);
            opacity: 1;
          }
        }
      }
      p {
        color: var(--color-white);
        font-size: 2rem;
        line-height: 1.8;
        opacity: 0;
        animation: txt-Up 0.7s 0.5s both;
        @-webkit-keyframes txt-Up {
          0% {
            visibility: visible;
            opacity: 0;
            transform: translate3d(0, 50%, 0);
            filter: blur(8px);
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            filter: blur(0);
          }
        }
        @keyframes txt-Up {
          0% {
            visibility: visible;
            opacity: 0;
            transform: translate3d(0, 50%, 0);
            filter: blur(8px);
          }
          100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            filter: blur(0);
          }
        }
      }
    }
    .scroll-down {
      position: absolute;
      left: 50%;
      bottom: 2rem;
      transform: translateX(-50%);
      color: var(--color-white);
      p {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        font-weight: 700;
      }
      &__bg {
        position: relative;
        left: 50%;
        width: 1px;
        height: 5rem;
        background-color: var(--color-white-rgba-25);
      }
      &__bar {
        position: relative;
        /* left: 50%; */
        left: calc(50% + 0.5px);
        transform: translateX(-50%);
        width: 1px;
        height: 2rem;
        background-color: var(--color-white);
        animation: move-down 1.2s both linear infinite;
        @keyframes move-down {
          0% {
            opacity: 0;
            transform: translateY(0) translateX(-1px);
          }
          35% {
            opacity: 1;
            transform: translateY(0.75rem) translateX(-1px);
          }
          70% {
            opacity: 1;
            transform: translateY(2rem) translateX(-1px);
          }
          100% {
            opacity: 0;
            transform: translateY(3rem) translateX(-1px);
          }
        }
      }
    }
  }
  .inner-container {
    &__sub {
      max-width: var(--cantainer-width);
      margin: 0 auto;
      padding: 8rem 1.6rem;
    }
    h3 {
      position: relative;
      margin: 0 auto 8rem;
      text-transform: uppercase;
    }
    h4 {
      font-size: 4.5rem;
      margin: 0 auto 5rem;
    }
    .info-txt {
      font-size: 2rem;
      font-weight: 500;
    }
    .info-box {
      font-size: 2rem;
      background: var(--color-gray-F6F8FC);
    }
  }

  @media ${({ theme }) => theme.media.lgMin} {
    .sub-inner {
      &::before {
        height: 9.5rem;
      }
      [class*='__title'] {
        text-align: center;
        p {
          margin-top: 2rem;
        }
      }
    }
    .sub-visual {
      [class*='__title'] {
        h2 {
          font-size: 10rem;
        }
        p {
          max-width: 60rem;
        }
      }
    }
    .inner-container {
      h3 {
        font-size: 6.8rem;
      }
      .info-box {
        padding: 4.5rem 10rem;
      }
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    .sub-inner {
      &::before {
        height: 6.8rem;
      }
    }
    .sub-visual {
      &__title {
        h2 {
          font-size: 8rem;
        }
      }
    }
    .inner-container {
      h3 {
        font-size: 5.5rem;
      }
      .info-box {
        padding: 2.5rem 4rem;
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    &:not(.sub-support) {
      .sub-visual {
        height: 48rem;
      }
    }
    .sub-visual {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--color-black-rgba-25);
      }
      &__title {
        position: relative;
        z-index: 1;
        margin-top: 6rem;
        text-align: center;
        p {
          padding: 0 4rem;
        }
      }
      .scroll-down {
        display: none;
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
  }
  @media ${({ theme }) => theme.media.sm} {
    .sub-inner {
      &::before {
        height: 7.5rem;
      }
    }
    .sub-visual {
      &__title {
        h2 {
          font-size: 7rem;
        }
      }
    }
    .inner-container {
      h3 {
        font-size: 5.2rem;
      }
      .info-box {
        padding: 2rem 2.5rem;
      }
    }
  }
`;

SubContainerVeiw.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.element,
  title: PropTypes.string,
  subTxt: PropTypes.string,
  subVisual: PropTypes.string,
};

SubContainerVeiw.defaultProps = {
  isVisible: false,
  children: '',
  title: '',
  subTxt: '',
  subVisual: '',
};

export default SubContainerVeiw;
