import React from 'react';
import styled from 'styled-components';

import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/about/sub-visual.jpg';
import IntroductionBgImg from '../../../assets/img/about/introduction-bg.jpg';
import LogoCatchfunImg from '../../../assets/img/common/logo-catchfun.png';
import LogoKoreantistImg from '../../../assets/img/common/logo-koreantist.png';
import LogoKakaobank from '../../../assets/img/common/logo-kakaobank.png';
import LogoCeragem from '../../../assets/img/common/logo-ceragem.png';
import LogoSkHynixImg from '../../../assets/img/ourWork/logo-skhynix.png';
import HistoryIco from '../../../assets/img/about/ico-history.png';
import ashPhoto from '../../../assets/img/about/ash.png';
import pjaPhoto from '../../../assets/img/about/pja.png';
import kwgPhoto from '../../../assets/img/about/kwg.png';
import sswPhoto from '../../../assets/img/about/ssw.png';
import osbPhoto from '../../../assets/img/about/osb.png';
import ljyPhoto from '../../../assets/img/about/ljy.png';
import khyPhoto from '../../../assets/img/about/khy.png';
import kkhPhoto from '../../../assets/img/about/kkh.png';
import ycwPhoto from '../../../assets/img/about/ycw.png';

function AboutUsView() {
  return (
    <StyledSubContainer
      isVisible
      title="About"
      subTxt="바능은 창의적인 인재가 모여있는, 항상 새로운 기술에 도전하고,
    현재보다 나은 미래를 위해 바능 구성원들과 함께 만들어가고 있습니다."
      subVisual={SubVisualImg}
      className="sub-about"
    >
      <article className="c-history">
        <div className="inner-container__sub">
          <h3>프로젝트 수행이력 </h3>
          <ul className="c-history__list">
            <li className="list-item--2024">
              <div className="item-box">
                <div className="list-year">
                  <strong>2024</strong>
                  <span>
                    <img
                      src={LogoSkHynixImg}
                      className="logo-skHynix"
                      alt="Sk Hynix"
                    />
                    <img
                      src={LogoKakaobank}
                      className="logo-kakaobank"
                      alt="kakaobank"
                    />
                    <img
                      src={LogoCeragem}
                      className="logo-ceragem"
                      alt="ceragem"
                    />
                    <img
                      src={LogoKoreantistImg}
                      className="logo-koreantist"
                      alt="Koreantist"
                    />
                    <img
                      src={LogoCatchfunImg}
                      className="logo-catchfun logo-catchfun-2024"
                      alt="Catchfun"
                    />
                  </span>
                </div>
                <ul>
                  <li>세라젬 홈페이지 렌탈 결제 변경 구축</li>
                  <li>카카오뱅크 UI/UX 개선 사업</li>
                </ul>
              </div>
            </li>
            <li className="list-item--2023">
              <div className="item-box">
                <div className="list-year">
                  <strong>2023</strong>
                  <span>
                    <img
                      src={LogoSkHynixImg}
                      className="logo-skHynix"
                      alt="Sk Hynix"
                    />
                    <img
                      src={LogoKakaobank}
                      className="logo-kakaobank"
                      alt="kakaobank"
                    />
                    <img
                      src={LogoCeragem}
                      className="logo-ceragem"
                      alt="ceragem"
                    />
                    <img
                      src={LogoKoreantistImg}
                      className="logo-koreantist"
                      alt="Koreantist"
                    />
                    <img
                      src={LogoCatchfunImg}
                      className="logo-catchfun"
                      alt="Catchfun"
                    />
                  </span>
                </div>
                <ul>
                  <li>위치기반 할인쿠폰 앱 캐치펀 개발</li>
                  {/* <li>
                    한글의 우수함을 세계에 알리는 패션브랜드 코리안티스트 런칭
                  </li> */}
                  {/* <li>케이삼흥 / WOW330 사이트 구축 (바능 CMS 솔루션)</li> */}
                  <li>SK쉴더스 입주사 통합 보안 시스템 개발</li>
                  <li>SK Hynix 연구개발시료센터 장비자동화 환경구축</li>
                  <li>카카오뱅크 광고주플랫폼 구축</li>
                </ul>
              </div>
            </li>
            <li className="list-item--2022">
              <div className="item-box">
                <div className="list-year">
                  <strong>2022</strong>
                  <span>
                    <img
                      src={LogoSkHynixImg}
                      className="logo-skHynix"
                      alt="Sk Hynix"
                    />
                  </span>
                </div>
                <ul>
                  <li>Scope3 탄소배출량 산정시스템 구축</li>
                  <li>반독점 관리시스템</li>
                  <li>환경규제평가 Process 및 Risk Management Modernization</li>
                  <li>실장기 개발 관리 시스템 구축</li>
                  <li>SV Portal 고도화</li>
                  <li>IT기반의 ESG 플랫폼 구축</li>
                  <li>소통플랫폼</li>
                </ul>
              </div>
            </li>
            <li className="list-item--2021">
              <div className="item-box">
                <div className="list-year">
                  <strong>2021</strong>
                  <span>
                    <img
                      src={LogoSkHynixImg}
                      className="logo-skHynix"
                      alt="Sk Hynix"
                    />
                  </span>
                </div>
                <ul>
                  <li>연구개발 시료관리 3과제 구축_개발</li>
                  <li>미기원 요소기술 과제관리 시스템 구축</li>
                  <li>전사 SV 활동 체계 및 SV Account 확대 구축</li>
                  <li>산업보안포털 기능 및 구조개선 횡전개</li>
                </ul>
              </div>
            </li>
            <li className="list-item--2020">
              <div className="item-box">
                <div className="list-year">
                  <strong>2020</strong>
                </div>
                <ul>
                  <li>주식회사 바능설립</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </article>
      <article className="c-introduction">
        <div className="inner-container__sub">
          <h3>회사소개</h3>
          <p className="info-txt">
            행복을 쫓아가기만 하면 행복할 수가 없습니다. 지금이 행복해야 행복한
            인생입니다. <br />
            행복을 주는 회사 젊고 창의적인 인재들이 모여있는 회사 <br />
            항상 새로운 기술에 도전하는 회사 2024년도 더욱 더 성장할 회사, 꼰대
            마인드가 없는 회사, <br />
            누구나 존중받는 회사 우리가 이룬 것들을 바능 가족들과 함께합니다.
          </p>
        </div>
      </article>
      <article className="c-members">
        <div className="inner-container__sub">
          <h3>Members</h3>
          <p className="info-txt">
            일과 결과가 중요한 세상에서도 결국 가장 중요한 것은 그 일과 결과를
            만들어 낸 사람, 그 자체입니다.
          </p>
          <ul className="c-members__list">
            <li>
              <span>
                <img src={ashPhoto} alt="안세훈" />
              </span>
              <strong>대표이사 안세훈 개발</strong>
            </li>
            <li>
              <span>
                <img src={pjaPhoto} alt="박정아" />
              </span>
              <strong>부대표 박정아 개발</strong>
            </li>
            <li>
              <span>
                <img src={kwgPhoto} alt="김우곤" />
              </span>
              <strong>전무 김우곤 개발</strong>
            </li>
            <li>
              <span>
                <img src={sswPhoto} alt="손성원" />
              </span>
              <strong>사외이사 손성원 개발</strong>
            </li>
            <li>
              <span>
                <img src={osbPhoto} alt="오성범" />
              </span>
              <strong>개발 오성범</strong>
            </li>
            <li>
              <span>
                <img src={ljyPhoto} alt="이정엽" />
              </span>
              <strong>개발 이정엽</strong>
            </li>
            <li>
              <span>
                <img src={khyPhoto} alt="김학윤" />
              </span>
              <strong>개발 김학윤</strong>
            </li>
            <li>
              <span>
                <img src={kkhPhoto} alt="김경화" />
              </span>
              <strong>퍼블리셔 김경화</strong>
            </li>
            <li>
              <span>
                <img src={ycwPhoto} alt="임창원" />
              </span>
              <strong>디자이너 임창원</strong>
            </li>
          </ul>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  .c-history {
    h3 {
      text-align: center;
    }
    &__list {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: 22vh;
        left: 50%;
        display: inline-block;
        border: 1px dashed var(--color-gray-c8c8c8);
      }
      &::after {
        content: '';
        position: absolute;
        top: -10.2rem;
        left: 50%;
        transform: translateX(-45%);
        display: inline-block;
        height: 0;
        width: 0;
        border-width: 0 8px 17px;
        border-style: solid;
        border-color: transparent transparent var(--color-gray-c8c8c8);
      }
      [class^='list-item'] {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-bottom: 9rem;
        &[class*='--2020'] {
          .item-box {
            &::before {
              top: -1.5rem;
              width: 43px;
              height: 70px;
              border-radius: 0;
              border: none;
              background: url(${HistoryIco}) no-repeat center / 43px;
            }
          }
        }
        .item-box {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            display: inline-block;
            border: 6px solid var(--color-main);
            border-radius: 50%;
            background: var(--color-white);
          }
          .list-year {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            strong {
              font-size: 4rem;
              font-weight: 600;
            }
            img {
              margin: 0.5rem 2rem;
              &.logo-catchfun {
                width: 15.6rem;
                height: auto;
              }
              &.logo-catchfun-2024 {
                margin-top: 1rem;
              }
              &.logo-koreantist {
                width: 15rem;
                height: auto;
                margin-top: 3px;
              }
              &.logo-skHynix {
                height: 4.4rem;
                margin-bottom: 1rem;
              }
              &.logo-kakaobank {
                height: 3rem;
              }
              &.logo-ceragem {
                height: 2rem;
              }
            }
          }
          ul {
            li {
              position: relative;
              padding: 0.3rem 0;
              font-size: 1.8rem;
              &::before {
                content: '';
                position: absolute;
                top: 1.5rem;
                display: inline-block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: var(--color-gray-969696);
              }
            }
          }
        }
      }
    }
  }
  .c-introduction {
    color: var(--color-white);
    background-image: url(${IntroductionBgImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    > div {
      position: relative;
      h3 {
        margin-bottom: 4rem;
      }
      .info-txt {
        font-weight: 200;
      }
    }
  }
  .c-members {
    text-align: center;
    h3 {
      margin-bottom: 4rem;
    }
    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        span {
          display: inline-block;
          margin-bottom: 1rem;
          border: 1px solid var(--color-gray-969696);
          border-radius: 50%;
          overflow: hidden;
          img {
            margin-top: 5%;
            width: 85%;
          }
        }
        strong {
          display: block;
          font-size: 1.8rem;
          font-weight: 400;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    .c-history {
      &__list {
        top: 6rem;
        &::before {
          top: -8rem;
        }
        [class^='list-item'] {
          &[class*='--2020']:nth-child(even) {
            .item-box {
              &::before {
                left: -8.7rem;
              }
            }
          }
          &:nth-child(odd) {
            text-align: right;
            .item-box {
              &::before {
                right: -8.6rem;
                left: auto;
              }
              .list-year {
                flex-direction: row-reverse;
              }
              ul {
                li {
                  padding-right: 1rem;
                  &::before {
                    right: 0;
                  }
                }
              }
            }
          }
          &:nth-child(even) {
            flex-direction: row-reverse;
            .item-box {
              &::before {
                left: -8.3rem;
              }
              ul {
                li {
                  padding-left: 1rem;
                  &::before {
                    left: 0;
                  }
                }
              }
            }
          }
          .item-box {
            width: calc(50% - 7rem);
            &::before {
              top: 1.5rem;
              left: 2px;
              width: 1.8rem;
              height: 1.8rem;
            }
          }
        }
      }
    }
    .c-introduction {
      height: 68vh;
      background-position: center;
      > div {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .c-members {
      &__list {
        li {
          width: calc(100% / 5);
          margin-top: 10rem;
          span {
            width: 20rem;
            height: 20rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    .c-history {
      &__list {
        [class^='list-item'] {
          &[class*='--2020'] {
            .item-box {
              &::before {
                left: 0;
                background-size: 30px;
              }
            }
          }
          .item-box {
            padding-left: 6rem;
            &::before {
              top: 2rem;
              left: 0.8rem;
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
    .c-introduction {
      > div {
        padding-top: 16rem;
        padding-bottom: 16rem;
      }
    }
    .c-members {
      &__list {
        li {
          width: calc(100% / 3);
          margin-top: 5rem;
          span {
            width: 17rem;
            height: 17rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    .c-history {
      &__list {
        &::before {
          top: -2rem;
          left: 1.6rem;
        }
        &::after {
          display: none;
        }
        [class^='list-item'] {
          .item-box {
            ul {
              li {
                padding-left: 1rem;
                &::before {
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
    .c-introduction {
      text-align: center;
      background-position: 55% center;
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-history {
      &__list {
        [class^='list-item'] {
          .item-box {
            .list-year {
              align-items: flex-start;
              flex-direction: column;
              span {
                img {
                  margin-left: 0;
                  margin-right: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
    .c-history {
      &__list {
        &::before {
          bottom: 18vh;
        }
        [class^='list-item'] {
          &[class*='--2020'] {
            .item-box {
              &::before {
                left: 0;
                width: 30px;
                background-size: 20px;
              }
            }
          }
          .item-box {
            padding-left: 6rem;
            &::before {
              top: 2rem;
              left: 0.8rem;
              width: 3px;
              height: 3px;
            }
          }
        }
      }
    }
    .c-introduction {
      > div {
        padding-top: 14rem;
        padding-bottom: 14rem;
        .info-txt {
          padding: 0 4.8rem;
        }
      }
    }
    .c-members {
      &__list {
        li {
          width: calc(100% / 2);
          margin-top: 3rem;
          span {
            width: 17rem;
            height: 17rem;
          }
        }
      }
    }
  }
`;

export default AboutUsView;
