import React from 'react';
import styled from 'styled-components';

import subHeaderImg from './img/sub-header.jpg';
import subImg from './img/sub-img.jpg';
import footerImg from './img/footer.jpg';

function Sub() {
  return (
    <StyledSubContainer>
      <div className="inner">
        <img src={subHeaderImg} alt="" />
        <img src={subImg} alt="" className="sub" />
        <img src={footerImg} alt="" />
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled.div`
  .inner {
    max-width: 1920px;
    margin: 0 auto;
    .sub {
      margin-bottom: 12rem;
    }
  }
`;

export default Sub;
