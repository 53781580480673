import {
  useQuery as _useQuery,
  useQueries as _useQueries,
  useMutation as _useMutation,
} from 'react-query';

import { defaultQuery, fileUpload } from '../config/utils/network';

/**
 * GET에 해당하는 리퀘스트
 * @param {*} uri
 * @param {*} data
 * @param {*} options
 * @returns
 */
export const useQuery = (uri, data = {}, options = {}) => {
  const result = _useQuery([uri, data], () => defaultQuery(uri, data), {
    onError,
    ...options,
    select: res => {
      if (invalidResultCode(res, options.onError) === false) {
        return;
      }
      const data = getSelectData(res);

      if (typeof options.select === 'function') {
        return options.select(data);
      }
      return data;
    },
    onSettled: (...res) => {
      if (typeof options.onSettled === 'function') {
        options.onSettled(...res);
      }
    },
  });

  return result;
};

/**
 * get을 동시 요청하는 리퀘스트
 * @param {*} queries
 * @returns
 */
export const useQueries = queries => {
  return _useQueries(
    queries.map(([uri, data, ...options]) => ({
      queryKey: [uri, data],
      queryFn: () => defaultQuery(uri, data),
      ...options,
      select: res => {
        const data = getSelectData(res);

        if (typeof options.select === 'function') {
          return options.select(data);
        }
        return data;
      },
    })),
  );
};

/**
 * 뮤테이션 용 리퀘스트
 * @param {*} uri
 * @param {*} options
 * @returns
 */
export const useMutation = (uri, options = {}, isMultipart = false) => {
  const mutationFn = data =>
    isMultipart ? fileUpload(uri, data) : defaultQuery(uri, data);

  const result = _useMutation(mutationFn, {
    onError,
    ...options,
    onSuccess: res => {
      if (invalidResultCode(res, options.onError) === false) {
        return;
      }

      const data = getSelectData(res);

      if (typeof options.onSuccess === 'function') {
        options.onSuccess(data);
      }
    },
  });

  return result;
};

/**
 * 에러 핸들러
 * @param {*} error
 */
const onError = error => {};

/**
 * 유효 status 코드 처리
 * @param {*} res
 * @param {*} onError
 * @returns
 */
const invalidResultCode = (res, onError) => {
  if (res.status !== 200) {
    if (typeof onError === 'function') {
      onError(res);
    }
    return false;
  }
};

/**
 * 응답 값을 필요한 데이터 형태로 변환
 * @param {*} res
 * @returns
 */
const getSelectData = res => res.data;
