import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import SwiperCore, { Navigation, Pagination } from 'swiper';

const CustomSwiperView = React.forwardRef(
  ({ sliders, sliderOptions, setPrev, setTotal }, ref) => {
    setTotal(sliders.length - 1 ?? 1);

    SwiperCore.use([Navigation, Pagination]);
    return (
      <StyledSwiper
        className="work-swiper"
        {...sliderOptions}
        breakpoints={{
          576: {
            spaceBetween: 20,
          },
          768: {
            spaceBetween: 30,
          },
          1024: {
            spaceBetween: 50,
          },
        }}
        onBeforeInit={swiper => {
          ref.current = swiper;
        }}
        // onPaginationUpdate={swiper => {
        //   // setPrev((swiper?.realIndex ?? 0) + 1);
        //   // console.log('swiper =>', swiper);
        // }}
        onTransitionEnd={swiper => {
          setPrev((swiper?.realIndex ?? 0) + 1);
        }}
      >
        {sliders?.map(slider => {
          return (
            <SwiperSlide>
              <Link to={slider.href} className="item">
                <figure>
                  <img src={slider.img} alt={slider.alt} />
                  {slider?.content && (
                    <figcaption>
                      <span>{slider.title}</span>
                      {slider.content}
                    </figcaption>
                  )}
                </figure>
              </Link>
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    );
  },
);

const StyledSwiper = styled(Swiper)`
  .item {
    figure {
      /* width: 50rem; */
      img {
        /* width: 100%; */
        margin-bottom: 4rem;
        box-shadow: 0px 8px 6px 0px var(--color-black-rgba-25);
      }
      figcaption {
        color: var(--color-white);
        font-size: 2rem;
        font-weight: 500;
        span {
          display: block;
          font-weight: 200;
        }
      }
    }
  }
`;

CustomSwiperView.propTypes = {
  sliders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array])),
  sliderOptions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object])),
  setPrev: PropTypes.func,
  setTotal: PropTypes.func,
};

CustomSwiperView.defaultProps = {
  sliders: [],
  sliderOptions: {},
  setPrev: () => {},
  setTotal: () => {},
};
export default CustomSwiperView;
