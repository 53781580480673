import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// ① 2 3 4 5
// 1 ② 3 4 5
// 1 2 ③ 4 5
//   2 3 ④ 5
//     3 4 ⑤
function Pagination(props) {
  const { pageSize, currentPageNo, totalRecordCount, onPaging } = props;

  const numPages = Math.ceil(totalRecordCount / pageSize); // 페이지
  const startNum = currentPageNo - 2 < numPages ? currentPageNo - 2 : numPages; // 시작 넘버
  const endNum = currentPageNo + 2 > numPages ? currentPageNo + 2 : numPages; // 종료 넘버

  /**
   * 페이징 렌더링
   * @param {*} current 현재 페이지 번호
   * @param {*} startNum 시작 페이지 번호
   * @param {*} endNum 끝 페이지 번호
   * @param {*} onChange 변경 시 이벤트 핸들러
   * @returns <li><Link>1</Link></li>
   */
  const rendering = (current, startNum, endNum, onChange) => {
    const _startNumber = startNum < 1 ? 1 : startNum;
    const _endNumber = endNum > numPages ? numPages : endNum;

    const result = [];

    for (let i = _startNumber; i <= _endNumber; i += 1) {
      result.push(
        <li className={current === i ? 'active' : null}>
          <Link
            to="#"
            title={current === i ? '현재페이지' : null}
            onClick={() => {
              onChange && onChange(i, pageSize);
            }}
          >
            <span>{i}</span>
          </Link>
        </li>,
      );
    }
    return result;
  };

  return (
    totalRecordCount > 0 && (
      <StyledPagination className="c-bbs__paging">
        <ul>
          <li className="btn--first">
            <Link
              to="#"
              aria-label="first"
              title="첫 페이지로 이동"
              onClick={() => {
                onPaging && onPaging(1, pageSize);
              }}
            >
              <span className="sr-only">첫 페이지</span>
            </Link>
          </li>
          <li className="btn--prev">
            <Link
              to="#"
              aria-label="Previous"
              title="이전 페이지로 이동"
              onClick={() => {
                const preIndex = currentPageNo - 1 > 0 ? currentPageNo - 1 : 1;
                onPaging && onPaging(preIndex, pageSize);
              }}
            >
              <span className="sr-only">이전 페이지</span>
            </Link>
          </li>
          {rendering(currentPageNo, startNum, endNum, onPaging)}
          <li className="total">
            <span>/</span> <span> {numPages ?? 1}</span>
          </li>
          <li className="btn--next">
            <Link
              to="#"
              aria-label="Next"
              title="다음 페이지로 이동"
              onClick={() => {
                const nextIndex =
                  currentPageNo + 1 > numPages
                    ? currentPageNo
                    : currentPageNo + 1;
                onPaging && onPaging(nextIndex, pageSize);
              }}
            >
              <span className="sr-only">다음 페이지</span>
            </Link>
          </li>
          <li className="btn--last">
            <Link
              to="#"
              aria-label="last"
              title="마지막 페이지로 이동"
              onClick={() => {
                onPaging && onPaging(numPages, pageSize);
              }}
            >
              <span className="sr-only">마지막 페이지</span>
            </Link>
          </li>
        </ul>
      </StyledPagination>
    )
  );
}

const StyledPagination = styled.div`
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      &.active {
        a {
          color: var(--color-black);
          text-decoration: underline;
          text-underline-position: under;
        }
      }
      &:not(.active) {
        a {
          &:hover,
          &:active {
            color: var(--color-main);
          }
        }
      }
      &.total {
        display: none;
      }
      &.btn {
        &--first,
        &--prev {
          a {
            &::before {
              content: '';
              position: absolute;
              top: 16px;
              display: block;
              height: 6px;
              width: 6px;
              border-width: 0 0 2px 2px;
              border-style: solid;
              border-color: var(--color-gray-323232);
              transform: rotate(45deg) translateX(-50%);
            }
            &::after {
              content: '';
              position: absolute;
              top: 16px;
              display: block;
              height: 6px;
              width: 6px;
              border-width: 0 0 2px 2px;
              border-style: solid;
              border-color: var(--color-gray-323232);
              transform: rotate(45deg) translateX(-50%);
            }
          }
        }
        &--first {
          a {
            &::before {
              left: calc(50% + 3px);
            }
            &::after {
              left: calc(50% - 3px);
            }
          }
        }
        &--prev {
          a {
            &::before {
              left: calc(50%);
            }
            &::after {
              display: none;
            }
          }
        }
        &--next,
        &--last {
          a {
            &::before {
              content: '';
              position: absolute;
              top: 16px;
              display: block;
              height: 6px;
              width: 6px;
              border-width: 2px 2px 0 0;
              border-style: solid;
              border-color: var(--color-gray-323232);
              transform: rotate(45deg) translateX(-50%);
            }
            &::after {
              content: '';
              position: absolute;
              top: 16px;
              display: block;
              height: 6px;
              width: 6px;
              border-width: 2px 2px 0 0;
              border-style: solid;
              border-color: var(--color-gray-323232);
              transform: rotate(45deg) translateX(-50%);
            }
          }
        }
        &--next {
          a {
            &::before {
              left: calc(50% - 3px);
            }
            &::after {
              display: none;
            }
          }
        }
        &--last {
          a {
            &::before {
              left: calc(50% + 1px);
            }
            &::after {
              left: calc(50% - 5px);
            }
          }
        }
      }
      a,
      > span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2px;
        width: 33px;
        height: 33px;
        color: var(--color-gray-737373);
        font-weight: 500;
        vertical-align: middle;
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    margin-top: 6.4rem;
  }
  @media ${({ theme }) => theme.media.md} {
    margin-top: 3.2rem;
    ul {
      li {
        display: none;
        &[class^='btn--'],
        &.active {
          display: block;
        }
        &.total {
          display: flex;
          > span {
            &:first-child {
              width: auto;
            }
          }
        }
      }
    }
  }
`;

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  currentPageNo: PropTypes.number.isRequired,
  totalRecordCount: PropTypes.number.isRequired,
  onPaging: PropTypes.func.isRequired,
};

export default Pagination;
