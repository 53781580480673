import React from 'react';
import CollapseRowView from './CollapseRowView';

/**
 * Collapse Row Item
 * @param {*} props properties
 * @returns Collapse 1 Row
 */
function CollapseRow(props) {
  return <CollapseRowView {...props} />;
}

export default CollapseRow;
