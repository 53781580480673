import React from 'react';

import JoinCompleteView from './JoinCompleteView';

/**
 * 로그인 - 가입완료
 * @returns
 */
function JoinComplete() {
  return <JoinCompleteView />;
}

export default JoinComplete;
