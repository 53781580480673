import React, { useState } from 'react';

import MainView from './MainView';

import { useQuery } from '../../../hooks/useQuery';
import { SITE_ID } from '../../../config/constants';

/**
 * 사이트 관리
 * @returns
 */
function Main() {
  const [popups, setPopups] = useState([]);

  /**
   * API - 목록 조회
   */
  const {
    data,
    // ...restQuery
  } = useQuery(
    '/api/popup/selectPopupList',
    { siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!SITE_ID,
      onSuccess: res => {
        if (res) {
          setPopups(res.result);
        }
      },
    },
  );

  const { data: banners } = useQuery(
    '/api/admin/banner/selectBannerList',
    {
      siteId: SITE_ID,
      bannerGroupId: 'BNRGRP_0000000000200',
      searchCondition: '1',
      searchKeyword: '',
    },
    {
      cacheTime: 0,
      enabled: !!SITE_ID,
    },
  );

  const props = {
    popups,
    banners,
  };

  return <MainView {...props} />;
}

export default Main;
