import React from 'react';

import EmploymentView from './EmploymentView';

/**
 * 채용안내
 * @returns
 */
function Employment() {
  return <EmploymentView />;
}

export default Employment;
