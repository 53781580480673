import React from 'react';

import JoinCertifyView from './JoinCertifyView';

/**
 * 로그인 - 본인인증
 * @returns
 */
function JoinCertify() {
  return <JoinCertifyView />;
}

export default JoinCertify;
