import React from 'react';

import SurveyResultView from './SurveyResultView';

/**
 * 설문조사 - 결과페이지
 * @returns
 */
function SurveyResult() {
  return <SurveyResultView />;
}

export default SurveyResult;
