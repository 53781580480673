import React from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';

import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';
import PlaygroundIco1 from '../../../assets/img/product/ico-playground1.png';
import PlaygroundIco2 from '../../../assets/img/product/ico-playground2.png';
import PlaygroundIco3 from '../../../assets/img/product/ico-playground3.png';
import PlaygroundIco4 from '../../../assets/img/product/ico-playground4.png';
import PlaygroundIco5 from '../../../assets/img/product/ico-playground5.png';
import PlaygroundIco6 from '../../../assets/img/product/ico-playground6.png';
import PlaygroundIco7 from '../../../assets/img/product/ico-playground7.png';
import PlaygroundIco8 from '../../../assets/img/product/ico-playground8.png';

function PlaygroundView() {
  return (
    <StyledSubContainer isVisible title="" subTxt="" subVisual={SubVisualImg}>
      <article className="c-playground">
        <div className="inner-container__sub">
          <h3>캐치펀 개인정보처리방침</h3>
          <p>&nbsp;</p>
          <p>
            제1조(개인정보의 처리목적)
            <br />
            캐치펀은 개인정보를 다음 목적 이외의 용도로는 이용하지 않으며 이용
            목적 등이 변경될 경우에는 동의를 받아 처리하겠습니다.
            <br />
            <br />
            (1) 회사는 회원님의 서비스 이용과정에서 다음과 같이 개인정보를
            수집합니다.
            <br />
            &nbsp; &nbsp;- 회원가입 시 : 아이디, 비밀번호, 이름, 휴대전화번호,
            이메일 주소, 생년월일, 닉네임
            <br />
            &nbsp; &nbsp;- 본인인증 시 : 이름, 생년월일, 성별, 휴대전화번호
            <br />
            &nbsp; &nbsp;- 1:1 문의 시 : 이메일, 휴대전화번호
            <br />
            (2) 서비스 이용과정에서 아래 정보가 자동 생성되어 수집, 저장, 조합,
            분석될 수 있습니다.
            <br />
            &nbsp; &nbsp;- 쿠키, 방문기록, 서비스 이용기록,
            기기정보(기기고유번호, OS, 버전, 모델명 등)
            <br />
            <br />
            <br />
            <br />
            제2조(처리하는 개인정보 항목)
            <br />
            캐치펀은 다음의 개인정보 항목을 처리하고 있습니다.
            <br />
            <br />- 회원제 서비스 제공, 회원 식별, 휴대폰 본인인증, 회원관리
            <br />- 서비스 제공, 서비스 개선, 신규 서비스 개발, 맞춤 서비스 제공
            <br />- 문의 상담 및 불만 처리
            <br />- 서비스 방문 및 이용기록 통계 및 분석
            <br />- 서비스 만족도 조사 및 관리
            <br />- 이벤트 제공 및 경품 지급
            <br />- 마케팅 및 프로모션 활용 (광고성/이벤트 정보 제공)
            <br />- 고지사항 전달
            <br />
            <br />
            <br />
            제3조(개인정보의 처리 및 보유기간)
            <br />
            캐치펀은 법령에 따른 개인정보 보유 이용기간 또는 정보주체로부터
            개인정보를 수집 시에 동의 받은 개인정보 보유 이용기간 내에서
            개인정보를 처리보유합니다.
            <br />
            <br />
            민원신청 및 Q&amp;A 신청 비회원 정보 : 3년 (전자상거래 등에서의
            소비자보호에 관한 법률 및 시행령)
            <br />
            <br />
            <br />
            제4조(개인정보의 제3자 제공)
            <br />
            (1) 캐치펀은 서비스 향상을 위해 아래와 같이 이용자의 개인정보를
            위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게
            관리될 수 있도록 필요한 사항을 규정하고 있습니다.
            <br />
            (2) 캐치펀은 이용자의 개인정보를 위탁하는 업체 및 업무 내용은 아래와
            같습니다.
          </p>
          <br />
          <div style={{ textAlign: 'center' }}>
            <table>
              <thead>
                <tr>
                  <th>수탁업체</th>
                  <th>위탁업무의 내용</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>세종텔레콤(주)</td>
                  <td>비즈메세지(알림톡) 서비스</td>
                </tr>
                <tr>
                  <td>세종텔레콤(주)</td>
                  <td>문자, 이메일, 알림톡 발송 서비스</td>
                </tr>
                <tr>
                  <td>NICE평가정보(주)</td>
                  <td>본인인증 서비스</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
            <br />
            제5조(개인정보처리의 위탁)
            <br />① 캐치펀은 원활한 개인정보 업무처리를 위하여 다음과 같이
            개인정보 처리업무를 위탁하고 있습니다.
            <br />
            <br />② 캐치펀은 위탁계약 체결시 개인정보 보호법 제25조에 따라
            위탁업무 수행목적 외 개인정보 처리금지, 기술적&middot;관리적
            보호조치, 재위탁 제한, 수탁자에 대한 관리&middot;감독, 손해배상 등
            책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
            안전하게 처리하는지를 감독하고 있습니다.
            <br />
            <br />③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
            개인정보 처리방침을 통하여 공개하도록 하겠습니다.
            <br />
            <br />
            <br />
            제6조(정보주체의 권리의무 및 행사방법)
            <br />
            정보주체는 캐치펀에 대해 언제든지 개인정보
            열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를 행사할
            수 있습니다.
            <br />
            <br />
            캐치펀에 대해「개인정보보호법」시행령 제41조제1항에 따라 서면,
            전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 캐치펀은 이에
            대해 지체없이 조치하겠습니다.
            <br />
            정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
            있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른
            위임장을 제출하셔야 합니다.
            <br />
            개인정보 열람 및 처리정지 요구는「개인정보보호법」제35조 제5항,
            제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
            <br />
            개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
            대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            <br />
            한국표준정보망은 정보주체 권리에 따른 열람의 요구,
            정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
            본인이거나 정당한 대리인인지를 확인합니다.
            <br />
            <br />
            <br />
            제7조(개인정보의 파기)
            <br />
            캐치펀은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
            불필요하게 되었을 때에는 다음과 같이 지체없이 해당 개인정보를
            파기합니다.
            <br />
            <br />
            파기절차 : 불필요한 개인정보 및 개인정보파일은 내부방침 절차에 따라
            지체 없이 파기합니다.
            <br />
            <br />
            파기방법 : 전자적 형태의 개인정보는 기록을 재생할 수 없는 기술적
            방법을 사용하며, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
            통하여 파기합니다.
            <br />
            <br />
            <br />
            제8조(개인정보의 안전성 확보조치)
            <br />
            캐치펀은「개인정보보호법」제29조에 따라 개인정보의 안전성 확보를
            위해 다음과 같은 조치를 취하고 있습니다.
            <br />
            <br />
            관리적 조치 : 내부관리계획 수립ㆍ시행, 개인정보 담당자 대상 정기적
            직원 교육 등<br />
            <br />
            기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
            설치, 비밀번호 등의 암호화, 보안프로그램 설치
            <br />
            <br />
            물리적 조치 : 전산실, 자료보관실 등의 접근통제
            <br />
            <br />
            <br />
            제9조(개인정보 보호책임자)
            <br />
            캐치펀은 개인정보를 보호하고 개인정보와 관련된 사항을 처리하기
            위하여 아래와 같이 개인정보처리방침 책임자, 개인정보처리방침 담당자
            및 개인정보 열람청구를 접수ㆍ처리하는 부서 및 담당자를 지정하고
            있습니다.
            <br />
            <br />
            <br />
          </p>
          <div className="c-contactUs__btn">
            <Button href="/catchfun" size="md" variant="default">
              뒤로가기
            </Button>
          </div>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  .sub-visual {
    height: 200px !important;
  }
  h3,
  .info-txt {
    text-align: center;
  }
  .c-playground {
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10rem;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% / 3 - 1rem);
        margin: 1rem 0;
        padding: 5rem;
        text-align: center;
        &:nth-child(even) {
          background: var(--color-gray-F6F8FC);
        }
        &[class^='list-item'] {
          strong {
            font-size: 2.7rem;
            &::before {
              content: '';
              display: block;
              width: 24rem;
              height: 14.4rem;
              margin: 0px auto 3rem;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 24rem;
            }
          }
          &[class*='--type1'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco1});
              }
            }
          }
          &[class*='--type2'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco2});
              }
            }
          }
          &[class*='--type3'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco3});
              }
            }
          }
          &[class*='--type4'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco4});
              }
            }
          }
          &[class*='--type5'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco5});
              }
            }
          }
          &[class*='--type6'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco6});
              }
            }
          }
          &[class*='--type7'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco7});
              }
            }
          }
          &[class*='--type8'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco8});
              }
            }
          }
        }
        p {
          font-size: 1.8rem;
        }
      }
    }
  }
  .c-contactUs__btn {
    margin-top: 8rem;
    text-align: center;
    a {
      padding-right: 10rem;
      padding-left: 10rem;
      font-size: 1.7rem;
      font-weight: 500;
    }
  }
  @media ${({ theme }) => theme.media.xl} {
  }
  @media ${({ theme }) => theme.media.lg} {
    .inner-container {
      .info-txt {
        padding: 0 5rem;
      }
    }
    .c-playground {
      &__list {
        li {
          width: calc(100% / 2 - 1rem);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-playground {
      &__list {
        li {
          height: 40rem;
          p {
            min-height: 55px;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-playground {
      &__list {
        li {
          width: calc(100%);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
  }
`;

export default PlaygroundView;
