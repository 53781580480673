import React from 'react';
import { createPortal } from 'react-dom';

import CustomModalView from './CustomModalView';

function CustomModal({ message, onClose }) {
  const props = {
    message,
    onClose,
  };
  return createPortal(
    <CustomModalView {...props} />,
    document.getElementById('modal'),
  );
}

export default CustomModal;
