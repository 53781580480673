import React from 'react';

const KoreantistIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136"
      height="15.559"
      viewBox="0 0 136 15.559"
      {...props}
    >
      <g transform="translate(-1401 -1351.442)">
        <g transform="translate(1401 1351.442)">
          <path
            d="M8.792.947,4,7.436V.947H0V15.462H4V12.741l1.834-2.433,3.054,5.155H13.6L8.442,7.425,13.426.947Z"
            transform="translate(0 -0.385)"
            fill="#fff"
          />
          <g transform="translate(0 0)">
            <g>
              <path
                d="M37.308,11.786a4.511,4.511,0,0,0-2.323-.738,4.132,4.132,0,0,1-6.976,4.013A4.486,4.486,0,0,1,23.272,12.8a7.771,7.771,0,0,0,15.452.483,4.514,4.514,0,0,0-1.416-1.494"
                transform="translate(-9.384 -4.455)"
                fill="#fff"
              />
              <path
                d="M24.774,8.229a4.509,4.509,0,0,0,2.321.738,4.132,4.132,0,0,1,6.977-4.013A4.522,4.522,0,0,1,38.807,7.2a7.771,7.771,0,0,0-15.449-.482,4.491,4.491,0,0,0,.783,1.007,4.55,4.55,0,0,0,.633.5"
                transform="translate(-9.419 0)"
                fill="#fff"
              />
              <path
                d="M63.924,9.6a4.42,4.42,0,0,0,.806-.992,4.885,4.885,0,0,0,.512-1.216,5.332,5.332,0,0,0,.181-1.418,5.1,5.1,0,0,0-.4-2.043A4.629,4.629,0,0,0,63.9,2.345a5.135,5.135,0,0,0-1.712-1.03A6.214,6.214,0,0,0,60.015.947H53.56V15.456h4.022V10.964h1.344l2.262,4.492h4.6l-2.924-5.121A4.7,4.7,0,0,0,63.924,9.6M59.844,4.3a1.444,1.444,0,0,1,.6.128,1.574,1.574,0,0,1,.5.351,1.644,1.644,0,0,1,.337.533,1.893,1.893,0,0,1,0,1.339,1.523,1.523,0,0,1-.337.517,1.492,1.492,0,0,1-.5.331,1.586,1.586,0,0,1-.608.118H57.582V4.3"
                transform="translate(-21.598 -0.382)"
                fill="#fff"
              />
              <rect
                width="11.205"
                height="3.35"
                transform="translate(46.024 0.566)"
                fill="#fff"
              />
              <rect
                width="11.205"
                height="3.35"
                transform="translate(46.024 6.23)"
                fill="#fff"
              />
              <rect
                width="11.205"
                height="3.35"
                transform="translate(46.024 11.725)"
                fill="#fff"
              />
              <path
                d="M103.7.929,97.83,15.449h4.246l.747-1.963h5.494l.758,1.963h4.246L107.453.929Zm.4,9.239,1.48-4.033,1.475,4.033Z"
                transform="translate(-39.449 -0.374)"
                fill="#fff"
              />
              <path
                d="M136.1,8.724,130.055.947h-3.532v14.51h4.022V7.646l6.081,7.81h3.489V.947H136.1Z"
                transform="translate(-51.02 -0.381)"
                fill="#fff"
              />
              <path
                d="M160.151,4.3h3.819V.947H152.32V4.3h3.83V15.456h4Z"
                transform="translate(-61.422 -0.382)"
                fill="#fff"
              />
              <path
                d="M178.234,11.9h0Z"
                transform="translate(-71.872 -4.798)"
                fill="#fff"
              />
              <path
                d="M178.887,11.633a4.058,4.058,0,0,0-2.01,1.588,4.058,4.058,0,0,1-2.011,1.589v4.955h4.022Z"
                transform="translate(-70.513 -4.691)"
                fill="#fff"
              />
              <path
                d="M178.234,14.294h0Z"
                transform="translate(-71.872 -5.764)"
                fill="#fff"
              />
              <path
                d="M176.877,7.464h0a4.06,4.06,0,0,1,2.01-1.588V.9h-4.021V9.053a4.06,4.06,0,0,0,2.011-1.589"
                transform="translate(-70.514 -0.363)"
                fill="#fff"
              />
              <path
                d="M196.308,7.774a6.1,6.1,0,0,0-1.691-.9q-.993-.357-2.219-.666-.566-.117-1.062-.256a5.788,5.788,0,0,1-.864-.309,1.9,1.9,0,0,1-.581-.394.732.732,0,0,1-.214-.523,1,1,0,0,1,.075-.383.778.778,0,0,1,.272-.325,1.555,1.555,0,0,1,.544-.229,3.84,3.84,0,0,1,.891-.085,2.8,2.8,0,0,1,.906.123,1.478,1.478,0,0,1,.555.32,1,1,0,0,1,.272.443,1.807,1.807,0,0,1,.069.491h4.033a5.2,5.2,0,0,0-.357-1.976,4.051,4.051,0,0,0-1.046-1.5,4.667,4.667,0,0,0-1.713-.952,7.6,7.6,0,0,0-2.347-.332,7.313,7.313,0,0,0-4.656,1.232,4.326,4.326,0,0,0-1.521,3.569A3.026,3.026,0,0,0,185.9,6.38a3.094,3.094,0,0,0,.65.944,4.21,4.21,0,0,0,.912.683,8.213,8.213,0,0,0,1.029.485q.523.2,1.019.341t.859.245q.8.256,1.419.421a7.257,7.257,0,0,1,1.04.352,1.685,1.685,0,0,1,.635.448,1.109,1.109,0,0,1,.213.72,1.168,1.168,0,0,1-.1.491.909.909,0,0,1-.347.384,1.909,1.909,0,0,1-.656.245,5.154,5.154,0,0,1-1.029.085,3.229,3.229,0,0,1-1.846-.421,1.4,1.4,0,0,1-.6-1.222H185.09a5.381,5.381,0,0,0,.138,1.211,4.385,4.385,0,0,0,.448,1.158,4.475,4.475,0,0,0,.806,1.035,4.89,4.89,0,0,0,1.2.837,6.789,6.789,0,0,0,1.643.56,10.183,10.183,0,0,0,2.134.2,9.884,9.884,0,0,0,2.459-.293,6.426,6.426,0,0,0,2-.863,4.238,4.238,0,0,0,1.344-1.423,3.883,3.883,0,0,0,.491-1.972A4.481,4.481,0,0,0,197.38,9.1a3.652,3.652,0,0,0-1.072-1.327"
                transform="translate(-74.637 -0.129)"
                fill="#fff"
              />
              <path
                d="M208.377.947V4.3h3.83V15.456h4V4.3h3.819V.947Zm3.814,1.944h0Z"
                transform="translate(-84.027 -0.382)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default KoreantistIcon;
