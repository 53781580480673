import React from 'react';
import PropTypes from 'prop-types';
import SockJsClient from 'react-stomp';
import { TalkBox } from 'react-talk';

/**
 * 채팅 박스 View 컴포넌트
 * 채팅 박스 부분을 렌더링하여 화면에 제공한다.
 */
const ChatBoxView = React.forwardRef(
  (
    {
      wsSourceUrl,
      roomId,
      randomUserId,
      randomUserName,
      chatTemplate,
      setChatTemplate,
      onMessageReceive,
      onMessageSend,
    },
    ref,
  ) => {
    return (
      <div>
        <TalkBox
          topic={`/topic/${roomId}`}
          currentUserId={randomUserId}
          currentUser={randomUserName}
          messages={chatTemplate?.messages ?? []}
          onSendMessage={onMessageSend}
          connected={chatTemplate?.clientConnected ?? false}
        />

        <SockJsClient
          url={wsSourceUrl}
          topics={[`/topic/${roomId}`]}
          onMessage={onMessageReceive}
          ref={ref}
          onConnect={() => {
            setChatTemplate({ clientConnected: true });
          }}
          onDisconnect={() => {
            setChatTemplate({ clientConnected: false });
          }}
          debug={false}
          style={[{ width: '100%', height: '100%' }]}
        />
      </div>
    );
  },
);

ChatBoxView.propTypes = {
  wsSourceUrl: PropTypes.string,
  roomId: PropTypes.string,
  randomUserId: PropTypes.string,
  randomUserName: PropTypes.string,
  chatTemplate: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  setChatTemplate: PropTypes.func,
  onMessageReceive: PropTypes.func,
  onMessageSend: PropTypes.func,
};

ChatBoxView.defaultProps = {
  wsSourceUrl: '',
  roomId: '',
  randomUserId: '',
  randomUserName: '',
  chatTemplate: {},
  setChatTemplate: () => {},
  onMessageReceive: () => {},
  onMessageSend: () => {},
};

export default ChatBoxView;
