import React from 'react';
import ErrorPageView from './ErrorPageView';
/**
 * ErrorPage
 * @returns
 */
function ErrorPage() {
  return <ErrorPageView />;
}

export default ErrorPage;
