import React from 'react';
import PropTypes from 'prop-types';
import SiteLayoutView from './SiteLayoutView';

function SiteLayout({ children }) {
  const props = { children };
  return <SiteLayoutView {...props} />;
}

export default SiteLayout;

SiteLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
