import React from 'react';
import Provider from '../providers';

export const withInitializeProvider = WrapperdComponent => {
  const Component = props => {
    return (
      <Provider>
        <WrapperdComponent {...props} />
      </Provider>
    );
  };

  return Component;
};
