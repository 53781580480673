import React from 'react';
import styled from 'styled-components';

import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';
import PlaygroundIco1 from '../../../assets/img/product/ico-playground1.png';
import PlaygroundIco2 from '../../../assets/img/product/ico-playground2.png';
import PlaygroundIco3 from '../../../assets/img/product/ico-playground3.png';
import PlaygroundIco4 from '../../../assets/img/product/ico-playground4.png';
import PlaygroundIco5 from '../../../assets/img/product/ico-playground5.png';
import PlaygroundIco6 from '../../../assets/img/product/ico-playground6.png';
import PlaygroundIco7 from '../../../assets/img/product/ico-playground7.png';
import PlaygroundIco8 from '../../../assets/img/product/ico-playground8.png';

function PlaygroundView() {
  return (
    <StyledSubContainer
      isVisible
      title="Product"
      subTxt="Playground는 React JS + Spring Boot + 전자정부 프레임워크 기반의
      CMS 솔루션 입니다."
      subVisual={SubVisualImg}
    >
      <article className="c-playground">
        <div className="inner-container__sub">
          <h3>플레이그라운드</h3>
          <p className="info-txt">
            Playground는 React JS + Spring Boot + 전자정부 프레임워크 기반의 CMS
            솔루션 입니다.
          </p>
          <ul className="c-playground__list">
            <li className="list-item--type1">
              <strong>CMS</strong>
              <p>Contents Management System</p>
            </li>
            <li className="list-item--type2">
              <strong>사이트 관리</strong>
              <p>여러개의 사이트 추가하여 관리할 수 있습니다.</p>
            </li>
            <li className="list-item--type3">
              <strong>권한 관리</strong>
              <p>
                기본적으로 제공하는 권한 기능과 새로운 권한을 생성할 수 있는
                기능을 제공합니다.
              </p>
            </li>
            <li className="list-item--type4">
              <strong>컨텐츠 관리</strong>
              <p>
                정적인 컨텐츠 페이지를 생성하여 사이트에 추가할 수 있습니다.
              </p>
            </li>
            <li className="list-item--type5">
              <strong>커뮤니티 관리</strong>
              <p>
                커뮤니케이션 가능한 공간을 생성/삭제할 수 있는 커뮤니티 관리
                기능을 제공합니다.
              </p>
            </li>
            <li className="list-item--type6">
              <strong>게시판 관리</strong>
              <p>다양한 속성의 게시판을 사용할 수 있도록 기능을 제공합니다.</p>
            </li>
            <li className="list-item--type7">
              <strong>설문 관리</strong>
              <p>사이트에 설문 및 답변을 할 수 있도록 기능을 제공합니다.</p>
            </li>
            <li className="list-item--type8">
              <strong>채팅</strong>
              <p>사용자간 채팅을 할 수 있는 기능을 제공합니다. [개발 진행중]</p>
            </li>
          </ul>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  .c-playground {
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10rem;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% / 3 - 1rem);
        margin: 1rem 0;
        padding: 5rem;
        text-align: center;
        &:nth-child(even) {
          background: var(--color-gray-F6F8FC);
        }
        &[class^='list-item'] {
          strong {
            font-size: 2.7rem;
            &::before {
              content: '';
              display: block;
              width: 24rem;
              height: 14.4rem;
              margin: 0px auto 3rem;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 24rem;
            }
          }
          &[class*='--type1'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco1});
              }
            }
          }
          &[class*='--type2'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco2});
              }
            }
          }
          &[class*='--type3'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco3});
              }
            }
          }
          &[class*='--type4'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco4});
              }
            }
          }
          &[class*='--type5'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco5});
              }
            }
          }
          &[class*='--type6'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco6});
              }
            }
          }
          &[class*='--type7'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco7});
              }
            }
          }
          &[class*='--type8'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco8});
              }
            }
          }
        }
        p {
          font-size: 1.8rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.xl} {
  }
  @media ${({ theme }) => theme.media.lg} {
    .inner-container {
      .info-txt {
        padding: 0 5rem;
      }
    }
    .c-playground {
      &__list {
        li {
          width: calc(100% / 2 - 1rem);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-playground {
      &__list {
        li {
          height: 40rem;
          p {
            min-height: 55px;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-playground {
      &__list {
        li {
          width: calc(100%);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
  }
`;

export default PlaygroundView;
