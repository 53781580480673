import React from 'react';

import SearchView from './SearchView';

/**
 * 통합검색
 * @returns
 */
function Search() {
  return <SearchView />;
}

export default Search;
