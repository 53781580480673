import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SubVisualImg from '../../../assets/img/support/sub-visual.jpg';

function GalleryView({ dataList, onMoveDetail }) {
  return (
    <StyledGalleryUl className="c-bbs-gallery c-bbs-gallery__list">
      {dataList && dataList.length > 0 ? (
        dataList?.map(row => {
          return (
            <li>
              <Link
                onClick={() => {
                  onMoveDetail && onMoveDetail(row?.nttId);
                }}
              >
                <picture>
                  <img src={SubVisualImg} alt="" />
                </picture>
                <div className="desc">
                  <div className="title">
                    <strong>{row?.nttSj}</strong>
                  </div>
                  <div className="info">
                    <span className="category">알림</span>
                    <time className="date">{row?.frstRegisterPnttm}</time>
                  </div>
                </div>
              </Link>
            </li>
          );
        })
      ) : (
        <li className="empty">
          <i className="ri-edit-2-line" />
          <p>등록된 글이 없습니다.</p>
        </li>
      )}
    </StyledGalleryUl>
  );
}

const StyledGalleryUl = styled.ul`
  &.c-bbs {
    /* 갤러리형 게시판 */
    &-gallery {
      margin-top: 2rem;
      /* 갤러리형 게시판 : 리스트 */
      &__list {
        display: flex;
        flex-wrap: wrap;
        li {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
          }
          &.empty {
            width: 100%;
            padding: 8rem 0;
            font-size: 1.7rem;
            text-align: center;
            i {
              color: var(--color-gray-d9d9d9);
              font-size: 4.8rem;
            }
          }
          a {
            display: flex;
            flex-direction: column;
            color: var(--color-black);
            transition: 0.3s;
            &:hover {
              picture {
                img {
                  transform: scale(1.1);
                }
              }
            }
            picture {
              position: relative;
              width: 100%;
              height: 0;
              padding-bottom: 68%;
              margin-bottom: 2.4rem;
              overflow: hidden;
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.5s;
              }
            }
            .desc {
              flex: 1;
              min-width: 1%;
              .title {
                display: flex;
                align-items: center;
                strong {
                  font-size: 2.4rem;
                  font-weight: 600;
                  ${({ theme }) => theme.text.textOverflow}
                }
              }
              .info {
                display: flex;
                padding-top: 1.6rem;
                margin-top: 1.6rem;
                border-top: 1px solid var(--color-gray-eaeaea);
                .category {
                  position: relative;
                  color: var(--color-main);
                  text-align: center;
                  font-weight: 500;
                  &::after {
                    content: '';
                    position: absolute;
                    top: 4px;
                    bottom: 4px;
                    right: -1rem;
                    width: 1px;
                    background: var(--color-gray-eaeaea);
                  }
                  & + .date {
                    margin-left: 2rem;
                  }
                }
                .date {
                  color: var(--color-gray-737373);
                }
              }
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    /* 갤러리형 게시판 */
    &[class*='-gallery'] {
      /* 갤러리형 게시판 : 리스트 */
      &[class*='__list'] {
        gap: 40px;
        li {
          width: calc((100% - 80px) / 3);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    /* 갤러리형 게시판 */
    &[class*='-gallery'] {
      /* 갤러리형 게시판 : 리스트 */
      &[class*='__list'] {
        gap: 40px 20px;
        li {
          width: calc((100% - 20px) / 2);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
    /* 갤러리형 게시판 */
    &[class*='-gallery'] {
      /* 갤러리형 게시판 : 리스트 */
      &[class*='__list'] {
        gap: 30px;
        li {
          width: 100%;
        }
      }
    }
  }
`;

GalleryView.propTypes = {
  dataList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  onMoveDetail: PropTypes.func,
};

GalleryView.defaultProps = {
  dataList: [],
  onMoveDetail: () => {},
};

export default GalleryView;
