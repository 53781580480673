// Grid Link Cell Style
export const GRID_LINK_CELL_STYLE = {
  color: '#1890FF',
  // fontWeight: 'bold',
  cursor: 'pointer',
  // whiteSpace: 'normal',
  // lineHeight: '1.5rem',
};

/**
 * 사이트 아이디
 * 나중에는 환경 변수에서 받을 정보
 */
export const SITE_ID = 'SITE_000000000000001';
/**
 * 게시판 ID
 */
export const BBS_ID = {
  notice: 'BBSMSTR_000000000091',
  support: 'BBSMSTR_000000000101',
  tech: 'BBSMSTR_000000000192',
  guest: 'BBSMSTR_000000000201',
  recruit: 'BBSMSTR_000000000191',
  test: 'BBSMSTR_000000000241',
};
// 'SITE_000000000000001'; playground
// 'SITE_000000000000091'; company

/**
 * Local Storage 선언
 */
export const LOCAL_STORAGE = localStorage;

/**
 * Session Storage 선언
 */
export const SESSION_STORAGE = sessionStorage;

/**
 * 검색 정보 저장 용 변수
 */
export const SEARCH_KEY = 'SearchValue';

/**
 * 채팅 용 Endpoint
 */
export const chattingEndPointUrl = 'http://localhost:8080/chatting';
