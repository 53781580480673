import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SearchBar from '../../molecules/SearchBar';
import PageInfo from '../../molecules/PageInfo';
import Gallery from '../../molecules/Gallery/Gallery';
import Pagination from '../../molecules/Pagination';
import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/support/sub-visual.jpg';

function GalleryView(props) {
  const { paging } = props;

  return (
    <StyledSubContainer
      title="Support"
      subTxt="바능의 다양한 소식을 보실 수 있습니다."
      subVisual={SubVisualImg}
      className="sub-support"
    >
      <div className="inner-container__sub">
        <h3>갤러리형 게시판</h3>
        <SearchBar {...props} />
        {/* S::갤러리형 게시판 - 리스트 */}
        <article className="c-bbs">
          <PageInfo {...props} />
          <Gallery {...props} />
          <Pagination {...paging} />
        </article>
        {/* E::갤러리형 게시판 - 리스트 */}
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3 {
    text-align: center;
  }
  .c-bbs {
    margin-top: 12rem;
    &__total {
      font-size: 1.7rem;
      em {
        color: var(--color-main);
        font-weight: 500;
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-bbs {
      margin-top: 12rem;
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-bbs {
      margin-top: 6rem;
    }
  }
`;

GalleryView.propTypes = {
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  notiList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  dataList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onSearch: PropTypes.func,
};

GalleryView.defaultProps = {
  paging: {},
  notiList: [],
  dataList: [],
  searchOption: {},
  onSearch: () => {},
};

export default GalleryView;
