import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SearchBar from '../../molecules/SearchBar';
import PageInfo from '../../molecules/PageInfo';
import Pagination from '../../molecules/Pagination';
import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/support/sub-visual.jpg';

function NoticeView(props) {
  const { paging, notiList, dataList } = props;
  return (
    <StyledSubContainer
      isVisible
      title="Support"
      subTxt="바능의 다양한 소식을 보실 수 있습니다."
      subVisual={SubVisualImg}
      className="sub-support"
    >
      <div className="inner-container__sub">
        <h3>공지사항</h3>
        <SearchBar {...props} />
        {/* S::리스트형 게시판 - 리스트 */}
        <article className="c-bbs">
          <PageInfo {...props} />
          <ul className="c-bbs-list c-bbs-list__list">
            {notiList?.map(row => {
              return (
                <li>
                  <Link to={`/notice/detail/${row?.nttId}`}>
                    <span className="num">
                      <span className="notice">공지</span>
                    </span>
                    <span className="category">바능</span>
                    <div className="desc">
                      <div className="title">
                        <strong>{row?.nttSj}</strong>
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: row?.nttCn }} />
                    </div>
                    <time className="date">{row?.frstRegisterPnttm}</time>
                  </Link>
                </li>
              );
            })}
            {dataList && dataList.length > 0 ? (
              dataList?.map(row => {
                return (
                  <li>
                    <Link to={`/notice/detail/${row?.nttId}`}>
                      <span className="num">{row?.rnum}</span>
                      <span className="category">알림</span>
                      <div className="desc">
                        <div className="title">
                          <strong>{row?.nttSj}</strong>
                          {/* <span className="ico-new">
                          <span className="sr-only">새글</span>
                        </span>
                        <i className="ri-attachment-2">
                          <span className="sr-only">첨부파일</span>
                        </i> */}
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: row?.nttCn }} />
                      </div>
                      <time className="date">{row?.frstRegisterPnttm}</time>
                    </Link>
                  </li>
                );
              })
            ) : (
              <li className="empty">
                <i className="ri-edit-2-line" />
                <p>등록된 게시글이 없습니다.</p>
              </li>
            )}
          </ul>
          <Pagination {...paging} />
        </article>
        {/* E::리스트형 게시판 - 리스트 */}
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3 {
    text-align: center;
  }
  .c-bbs {
    margin-top: 12rem;
    &__total {
      font-size: 1.7rem;
      em {
        color: var(--color-main);
        font-weight: 500;
      }
    }
    /* 리스트 게시판 */
    &-list {
      /* 리스트 게시판 : 리스트 */
      &__list {
        margin-top: 2rem;
        border-top: 2px solid var(--color-black);
        li {
          position: relative;
          border-bottom: 1px solid var(--color-gray-d9d9d9);
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
          }
          &.empty {
            padding: 8rem 0;
            font-size: 1.7rem;
            text-align: center;
            i {
              color: var(--color-gray-d9d9d9);
              font-size: 4.8rem;
            }
          }
          a {
            display: flex;
            align-items: center;
            color: var(--color-black);
            transition: 0.3s;
            .num {
              flex: 0 0 10rem;
              text-align: center;
              .notice {
                display: inline-block;
                padding: 0.5rem 1rem;
                color: var(--color-white);
                border-radius: 0.25rem;
                background: var(--color-main);
              }
            }
            .category {
              flex: 0 0 12rem;
              color: var(--color-main);
              text-align: center;
              font-weight: 500;
            }
            .desc {
              flex: 1;
              min-width: 1%;
              .title {
                display: flex;
                align-items: center;
                strong {
                  font-size: 2.4rem;
                  font-weight: 600;
                  ${({ theme }) => theme.text.textOverflow}
                  & + p {
                    margin-top: 1.6rem;
                  }
                }
                .ico-new {
                  &::before {
                    content: 'N';
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    margin-left: 0.8rem;
                    line-height: 2rem;
                    vertical-align: middle;
                    color: var(--color-white);
                    font-size: 1.2rem;
                    text-align: center;
                    border-radius: 50%;
                    background: var(--color-red-FF3200);
                  }
                }
                i[class*='ri-attachment'],
                i[class*='ri-file'] {
                  margin-left: 0.5rem;
                  color: var(--color-blue-0e4194);
                  font-size: 2rem;
                }
              }
              p {
                color: var(--color-gray-737373);
                font-size: 1.7rem;
                ${({ theme }) => theme.text.textOverflow}
              }
            }
            .date {
              flex: 0 0 16rem;
              color: var(--color-gray-737373);
              text-align: center;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-bbs {
      margin-top: 12rem;
      /* 리스트 게시판 */
      &-list {
        /* 리스트 게시판 : 리스트 */
        &__list {
          li {
            a {
              padding: 3rem 0;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-bbs {
      margin-top: 6rem;
      &-list {
        /* 리스트 게시판 : 리스트 */
        &__list {
          li {
            a {
              display: block;
              padding: 1rem 0;
              .num {
                display: block;
                text-align: left;
                margin-bottom: 0.5rem;
                text-indent: -9999px;
                font-size: 0;
              }
              .category {
                display: inline-block;
                margin-bottom: 0.5rem;
              }
              .title {
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    }
  }
`;

NoticeView.propTypes = {
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  notiList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  dataList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onSearch: PropTypes.func,
};

NoticeView.defaultProps = {
  paging: {},
  notiList: [],
  dataList: [],
  searchOption: {},
  onSearch: () => {},
};

export default NoticeView;
