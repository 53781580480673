import React from 'react';

import CommunityCafeView from './CommunityCafeView';

/**
 * 커뮤니티 - 커뮤니티홈
 * @returns
 */
function CommunityCafe() {
  return <CommunityCafeView />;
}

export default CommunityCafe;
