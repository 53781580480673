import React from 'react';
import { createPortal } from 'react-dom';

import PopupZoneView from './PopupZoneView';

function PopupZone() {
  const props = {};

  return createPortal(
    <PopupZoneView {...props} />,
    document.getElementById('popupZone'),
  );
}

export default PopupZone;
