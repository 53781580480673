import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchBarView from './SearchBarView';

/**
 * 검색 Bar 공통
 * @param {*} param0
 * @returns
 */
function SearchBar({ searchOption, onSearch }) {
  const [searchCnd, setSearchCnd] = useState('');
  const [searchWrd, setSearchWrd] = useState('');

  const handleSetSearchCnd = ({ target: { value } }) => setSearchCnd(value);

  const handleSetSearchWrd = ({ target: { value } }) => setSearchWrd(value);

  const handleSearchTrigger = event => {
    onSearch && onSearch(event, { searchCnd, searchWrd });
  };

  const props = {
    searchCnd,
    searchWrd,
    searchOption,
    onSearchTrigger: handleSearchTrigger,
    onChangeCnd: handleSetSearchCnd,
    onChangeWrd: handleSetSearchWrd,
  };
  return <SearchBarView {...props} />;
}

SearchBar.propTypes = {
  searchCnd: PropTypes.string,
  searchWrd: PropTypes.string,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onSearch: PropTypes.func,
  onSearchTrigger: PropTypes.func,
  onChangeWrd: PropTypes.func,
};

SearchBar.defaultProps = {
  searchCnd: '',
  searchWrd: '',
  searchOption: {},
  onSearch: () => {},
  onSearchTrigger: () => {},
  onChangeWrd: () => {},
};

export default SearchBar;
