import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Draggable from 'react-draggable';

import Button from '../../atoms/Button';

import Popup from '../../molecules/Popup';

/**
 * Layer 팝업 View
 * @param {*} popup 팝업 정보
 * @param {*} onClose 닫기 이벤트 핸들러 > 개별 핸들러를 적용하기 위해 내부에서 처리 예정
 * @returns Layer 팝업
 */
function LayerPopupView({ popup, onClose }) {
  return (
    <Draggable>
      <Popup>
        <PopupWrap className="dimmed_layer_wrapper" value={popup}>
          <div className="full_layer">
            <div className="c-layer-popup">
              <div className="layer-popup__header sr-only">
                <h2>{`제목 ${popup?.popupTitleNm ?? '2023-xx-xx'}`}</h2>
              </div>
              <div className="layer-popup__body">
                {/* <div>{`팝업 유형 ${popup?.popupTy ?? '팝업 유형'}`}</div>
              <div>{`파일 아이디 ${popup?.fileId ?? '없음'}`}</div>
              <div>{`팝업 아이디 ${popup?.popupId ?? '팝업 아이디'}`}</div>
              <div>{`W 오프셋 ${popup?.popupWidthLc ?? '200'}`}</div>
              <div>{`H 오프셋 ${popup?.popupVrticlLc ?? '200'}`}</div>
              <div>{`W 사이즈 ${popup?.popupWidthSize ?? '500'}`}</div>
              <div>{`H 사이즈 ${popup?.popupVrticlSize ?? '500'}`}</div>
              <div>{`시작일 ${popup?.ntceBgnde ?? '2023-xx-xx'}`}</div>
              <div>{`종료일 ${popup?.ntceEndde ?? '2023-xx-xx'}`}</div>
              <div>{`중지 여부 ${popup?.stopvewSetupAt ?? 'Y'}`}</div>
              <div>{`게시 여부 ${popup?.ntceAt ?? 'Y'}`}</div>
              <div>{`사용 여부 ${popup?.useAt ?? 'Y'}`}</div> */}
                <img
                  src="https://reacting.kr/static/media/work-catchfun-img.f92be3b80f812f121196.jpg"
                  alt=""
                />
              </div>
              <div className="layer-popup__footer">
                <div className="today-check">
                  <label htmlFor="todaypopup">
                    <input type="checkbox" name="todaypopup" id="todaypopup" />
                    오늘하루 보지 않음
                  </label>
                </div>
                <Button
                  type="button"
                  size="xs"
                  variant="link"
                  onClick={() => {
                    onClose && onClose(false);
                  }}
                >
                  닫기 <i className="ri-close-fill" />
                </Button>
              </div>
            </div>
          </div>
        </PopupWrap>
      </Popup>
    </Draggable>
  );
}

const PopupWrap = styled.div`
  &.dimmed_layer_wrapper {
    .full_layer {
      position: absolute;
      top: ${props => `${props.value.popupVrticlLc}px`};
      right: 0;
      bottom: 0;
      left: ${props => `${props.value.popupWidthLc}px`};
      width: ${props => `${props.value.popupWidthSize}px`};
      /* height: ${props => `${props.value.popupWidthSize}px`}; */
      bottom: 0;
      z-index: 100;
      text-align: center;
      white-space: nowrap;
      font-size: 0;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      .c-layer-popup {
        z-index: 2;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        /* width: calc(100% - 56px); */
        width: 100%;
        min-height: 200px;
        max-height: 100%;
        min-width: 200px;
        white-space: normal;
        word-break: break-word;
        text-align: left;
        /* padding: 30px; */
        margin: auto;
        box-sizing: border-box;
        font-size: 16px;
        background: #fff;
        box-shadow: 3px 3px 10px var(--color-black-rgba-25);
        .layer-popup {
          &__header {
            h2 {
              font-weight: 500;
              font-size: 2rem;
            }
          }
          &__body {
          }
          &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 4.5rem;
            padding-left: 1.5rem;
            .today-check {
              font-size: 1.4rem;
              label {
                cursor: pointer;
              }
              input {
                margin-right: 0.6rem;
              }
            }
            button {
              height: 100%;
              font-size: 1.4rem;
              border-left: 1px solid #eee;
              i {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }
`;

LayerPopupView.propTypes = {
  popup: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  onClose: PropTypes.func,
};

LayerPopupView.defaultProps = {
  popup: {},
  onClose: () => {},
};

export default LayerPopupView;
