/* global kakao */
import React, { useEffect } from 'react';
import styled from 'styled-components';

const { kakao } = window;

/**
 * 카카오맵
 * @returns
 */
function KakaoMap() {
  const lat = 37.362522878839656;
  const lng = 127.10748574821524;

  useEffect(() => {
    const container = document.getElementById('map');

    const options = {
      center: new window.kakao.maps.LatLng(lat, lng),
      level: 3,
    };

    const map = new window.kakao.maps.Map(container, options);

    // 지도 기능 off
    // 드래그 및 마우스 휠 확대/축소 기능 off
    // map.setDraggable(false);
    // map.setZoomable(false);

    // 지도,위성 버튼 및 줌 기능 컨트롤러 적용
    const mapTypeControl = new kakao.maps.MapTypeControl();
    const zoomControl = new kakao.maps.ZoomControl();
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

    // 회사 위치 마커 적용
    const markerPosition = new kakao.maps.LatLng(lat, lng);
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(map);
  }, []);

  return <StyledMap id="map" />;
}

const StyledMap = styled.div`
  /* width: calc(100vw - 25.5vw);*/
  width: 100%;
  height: calc(100vh - 40vh);
`;

export default KakaoMap;
