import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CategoryView from './CategoryView';

function Category({ categoryOption, onCategory }) {
  const [category, setCategory] = useState('');

  const handleCategoryTrigger = (event, key) => {
    setCategory(key);
    onCategory && onCategory(event, { category: key });
  };

  const props = {
    category,
    categoryOption,
    onCategoryTrigger: handleCategoryTrigger,
  };
  return <CategoryView {...props} />;
}

Category.propTypes = {
  category: PropTypes.string,
  categoryOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onCategory: PropTypes.func,
  onCategoryTrigger: PropTypes.func,
  onChangeCategory: PropTypes.func,
};

Category.defaultProps = {
  category: '',
  categoryOption: {},
  onCategory: () => {},
  onCategoryTrigger: () => {},
  onChangeCategory: () => {},
};

export default Category;
